import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/the-howling-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1981 werewolf movie, The Howling"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 06</h1>
                    <h2>The Howling</h2>
                    <h3>May 1, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <h1>Transcript</h1>
                        <p><Link to="/episodes/the-howling">Back to the episode details</Link></p>
                        <div>
                            <p>Bryan White (00:01.423)
                            Alright, we are recording.</p>
                            <p>Bryan White (00:06.241)
                            You&#39;re listening to the Brothers Grimm podcast presented by the Cinema Suicide Film Society on SubSac. I&#39;m Brian White, editor in grief of Cinema Suicide. I&#39;m joined by my co-host and actual brother, Dave White. Dave, how you doing? Not bad, not bad. How&#39;s that pickle beer?</p>
                            <p>Dave (00:17.634)
                            Hey, how&#39;s it going?</p>
                            <p>Dave (00:22.638)
                            about to find out.</p>
                            <p>Bryan White (00:23.853)
                            Yeah. All right. So we practically grew up in neighborhood video stores and the steady diet of utter garbage that these. Yeah, yeah. Is it out? It&#39;s a little pickly. All right. All right. All right. All right. All right. So there&#39;s no one I enjoy. It&#39;s no one else I enjoy. Chopping it up with more about trashy movies and Dave.</p>
                            <p>Dave (00:29.894)
                            Holy shit! Oh wow! Sorry. Yeah, I was not expecting that. Oh! All right, well, carry on.</p>
                            <p>Dave (00:47.286)
                            Woo!</p>
                            <p>Bryan White (00:54.505)
                            Just before we get into it, here&#39;s a little housekeeping. If you want to keep up with us between episodes, you can also find us on Twitter and Instagram at GrimBrosPod at both. You can also contact us directly at GrimBrosPod at gmail.com with any questions, comments or suggestions. Do let us know if there&#39;s a movie that you love and would like to hear us give the business. Lastly, if you like what you hear, you can subscribe to us wherever you get your podcasts. You&#39;d be doing us a favor by leaving us a five star review on Apple podcasts.</p>
                            <p>Bryan White (01:23.669)
                            wanted to get all that out of the way right at the top of the show. So we&#39;ve got a good one tonight. I&#39;ve been looking forward to talking about this one. We warned you last time. So we&#39;ll give you a taste right now. We&#39;ll give you a little little taste.</p>
                            <p>Bryan White (03:11.629)
                            That&#39;s right, it&#39;s the howling. Joe Dante, we&#39;re finally at Joe Dante. And I&#39;m so pleased that we&#39;re finally kicking it off with this guy, with this one in particular.</p>
                            <p>Dave (03:24.59)
                            That&#39;s a banger. It&#39;s a banger of a movie.</p>
                            <p>Bryan White (03:24.697)
                            So, oh yeah, it&#39;s a hell of a flick, man. So, some facts about it. The year was 1981. We&#39;re back in the early 80s because it&#39;s kind of becoming a recurring trend with us. So, it&#39;s 1981. Big year for werewolves. If you didn&#39;t know this, there were four werewolf movies released in America in just that year and another one in Spain as well. Paul Nashie released</p>
                            <p>Bryan White (03:52.505)
                            Return of the Werewolf, which was a remake of the Werewolf vs. the Vampire Woman, which uh... Have you...</p>
                            <p>Dave (03:56.175)
                            Which is a utter garbage if you&#39;ve never seen it.</p>
                            <p>Bryan White (03:58.993)
                            I fucking love that one, dude. That one is so good. I haven&#39;t seen the remake. That&#39;s the one I have. I&#39;ve not seen the one that&#39;s that was released in 81, but I love the original. I think it&#39;s ridiculous and it&#39;s it&#39;s it&#39;s wicked spooky in parts. But yeah, so other the other movies that were released that year were so that was Return of the Werewolf, which is the Paul Nash one Full Moon High, which is a Larry Cohen movie, and I&#39;ve never seen it, but I watched the trailer. It&#39;s a comedy.</p>
                            <p>Dave (04:14.866)
                            So wait, what are the other ones?</p>
                            <p>Dave (04:26.622)
                            comedy, isn&#39;t it? It&#39;s like a high school comedy.</p>
                            <p>Bryan White (04:28.925)
                            Yeah, and this is a few years before Teen Wolf, and somehow Teen Wolf didn&#39;t get sued because it&#39;s basically the same movie except it&#39;s football instead of basketball. I know, I know. American, I did not know that.</p>
                            <p>Dave (04:36.53)
                            Everybody be everybody be ripping off Larry Cohen.</p>
                            <p>Dave (04:42.026)
                            Do you know how his sister got murdered? Like, like 10 years ago. Yeah, anyway, go on. Uh, kind of. It was like a robbery. It was a famous thing. She was like going home after a premiere of what the fuck was that? Cher and, uh, Christina Aguilar Burlesque. She was like, uh, uh, uh,</p>
                            <p>Bryan White (04:47.201)
                            Holy shit. Like a mysterious thing or like, what do you know about this?</p>
                            <p>Bryan White (05:03.066)
                            Oh yeah.</p>
                            <p>Dave (05:07.05)
                            PR person on that movie and she was going home after the premiere and she was like at an intersection and someone just shot her. And it turned out it was like a robbery kind of thing, but the guy was kind of a little bit nutty. So yeah, that&#39;s my digression.</p>
                            <p>Bryan White (05:13.165)
                            God damn!</p>
                            <p>Bryan White (05:19.689)
                            Yeah, that sucks. That sucks. Yeah. So, an American werewolf in London, obviously the John Landis movie. And the other is Wolfen, which is, have you ever seen it?</p>
                            <p>Dave (05:35.12)
                            I have tried, what is it? Albert Finney in it.</p>
                            <p>Bryan White (05:37.013)
                            Albert Finney, it&#39;s good. It&#39;s just, it&#39;s like technically a shape-shifter movie. It&#39;s not like, I don&#39;t recall actually seeing werewolves in it. They&#39;re kind of, I think it&#39;s.</p>
                            <p>Dave (05:48.582)
                            I know I&#39;ve tried to I&#39;ve tried to watch it. I don&#39;t think I&#39;ve ever made it through.</p>
                            <p>Bryan White (05:52.345)
                            I it&#39;s it&#39;s you know it&#39;s not bad it&#39;s yeah but um man that&#39;s a tough that&#39;s a tough lot to keep up with also released that year blood beach which is about the yeah the mod the monster the monster under the sand the omen 3 dead and buried which we got to get to at some point that&#39;s a that&#39;s a hell of a movie and Wes Craven&#39;s deadly blessing</p>
                            <p>Dave (06:03.374)
                            Man, that movie is real hard.</p>
                            <p>Dave (06:12.898)
                            Yeah, I love that movie.</p>
                            <p>Dave (06:20.054)
                            which is an extremely strange film.</p>
                            <p>Bryan White (06:23.137)
                            Yeah, I&#39;ve never seen it. No. Ah, maybe. Oh. Well, if if Wes Craven&#39;s doing Amish, I might have to I might have to check that one out.</p>
                            <p>Dave (06:25.238)
                            That&#39;s the Amish one, right?</p>
                            <p>Dave (06:28.466)
                            I think it&#39;s the Amish one.</p>
                            <p>Dave (06:35.926)
                            It&#39;s a really I think Sharon Stone is in it as well.</p>
                            <p>Bryan White (06:39.489)
                            Oh, okay. Okay. Now you&#39;re talking. Yep. So cast and crew, as we mentioned, Joe Dante is the director. He&#39;s one of my favorite. He&#39;s one of my favorite. 80s horror movie directors before Quentin Tarantino or Rob Zombie were out there like referencing their favorite movies in their own movies. Joe Dante was out there doing it was kind of his it was kind of his thing. Like a lot of movie like a lot of filmmakers from</p>
                            <p>Dave (06:43.222)
                            That&#39;s a very odd movie.</p>
                            <p>Bryan White (07:08.205)
                            the 70s who would go on to sort of shape the industry in the 80s Dante was one of Roger Corman&#39;s guys. And prior to this, he and the Howlings writer John sales worked on Piranha which is</p>
                            <p>Dave (07:13.629)
                            Yes. Yeah, John Sayles was also from the Carmen school.</p>
                            <p>Bryan White (07:22.805)
                            Yeah, yeah, like, I mean, it&#39;s it&#39;s. Corman produced so many people who would go on to do like really, really big things and also like really small things, but like his. Like his influence on Hollywood. Is like you cannot be overstated, like he produced. Yeah, yeah.</p>
                            <p>Dave (07:41.747)
                            You don&#39;t have to sell me, you know how I feel about him.</p>
                            <p>Bryan White (07:45.953)
                            But yeah, so before this, the two of them made Piranha, which is a Jaws ripoff. It&#39;s way better than it has any right to be, and it&#39;s wicked funny.</p>
                            <p>Dave (07:54.71)
                            I believe that&#39;s actually what Steven Spielberg said about it. Someone said that about it. That this movie is better than it has any right to be.</p>
                            <p>Bryan White (07:59.197)
                            like, I&#39;m not sure. But well, yeah, like, and this movie, like, Piranha and then the howling were like huge going forward in the 80s because this was 1981, 1982, ET comes out and who&#39;s the mom in that movie? D Wallace, who&#39;s, you know, the star in this one.</p>
                            <p>Bryan White (08:23.889)
                            And also Steven Spielberg who at this time has his fingerprints on basically everything. He gets Dante to do Gremlins which was like the other fucking sensation in 1984 apart from like Purple Rain and Ghostbusters and you know everything that came out that year. So like this the confluence of events in this movie and everything the movies that came before it like it&#39;s had a really oversized impression.</p>
                            <p>Bryan White (08:52.493)
                            the 1980s. And I love Joe Dante. There&#39;s something about the way that he makes movies, that there is a sort of like gleeful abandon in the mayhem that he does. Like Gremlins, I think, is outstanding. And like there&#39;s that</p>
                            <p>Dave (09:07.008)
                            He is, he&#39;s someone who you can tell genuinely loves films, like just like across the board, like he just loves the magic of movies. He was, did you know when they did, when Arrow did the reissue of, I think it&#39;s Blood and Black Lace? It&#39;s either Blood and Black Lace or Whip in the Body. I think it&#39;s Blood and Black Lace is the one that&#39;s about the fashion industry, right?</p>
                            <p>Bryan White (09:12.269)
                            Yeah.</p>
                            <p>Bryan White (09:16.405)
                            Yeah. Yeah.</p>
                            <p>Bryan White (09:31.058)
                            Yes? Yes, yes.</p>
                            <p>Dave (09:31.882)
                            I guess that one when Arrow did their like, you know, 4K restoration of it, they used Joe Dante&#39;s print because he has like the best print of that film that exists in the world.</p>
                            <p>Bryan White (09:43.649)
                            makes sense because like he also gave us trailers from Hell, which has been pretty quiet lately, but like there was a period there for a few years where like trailers from Hell was like fucking huge. Yeah, that&#39;s like, I think most of the trailers come from like his, are like sourced from his personal collection, but like no, he&#39;s all over that. That&#39;s his project with a couple other people, obviously, but</p>
                            <p>Dave (09:56.258)
                            Wait, that&#39;s he did that? Oh, from his collection.</p>
                            <p>Bryan White (10:09.997)
                            But yeah, that was primarily his thing.</p>
                            <p>Bryan White (10:16.321)
                            But yeah, like he like there&#39;s a couple other movies throughout the 80s of his that I think are great. Like the Burbs is one of my favorite comedies ever. It&#39;s just fucking hilarious. But you can see it like he watched a ton of monster movies and horror movies when he was a kid and just puts it on screen as you&#39;ll see in this one because this movie is loaded with references to other movies and as well as like people who had a real definite impression on him. So the cast is D Wallace,</p>
                            <p>Bryan White (10:45.825)
                            who we mentioned. After this, she becomes America&#39;s mom with ET. Following that, it&#39;s almost like she can&#39;t not be cast as mothers.</p>
                            <p>Dave (10:55.115)
                            But also her career, like it didn&#39;t go the way that it should have, I think, like, I don&#39;t know if she got pigeonholed as a horror actor or what, but like she&#39;s a really good actor.</p>
                            <p>Dave (11:07.818)
                            And she should have, and she&#39;s in some fucking huge movies, but it&#39;s like after she makes ET, she seems to do a lot of horror and just kind of get like, you know, dumped into that bucket.</p>
                            <p>Bryan White (11:07.897)
                            She&#39;s great.</p>
                            <p>Bryan White (11:15.977)
                            Yeah. Yeah.</p>
                            <p>Bryan White (11:20.509)
                            It does seem like as much money as horror makes as like an industry item, it does seem like it&#39;s a bit of a gravity well. Like if you get into it, it&#39;s really, really hard to get out of it, because like, especially if you&#39;re in a movie like this or like Halloween or something, something really iconic, where this particular movie made a particularly.</p>
                            <p>Bryan White (11:42.889)
                            huge amount of money. So everybody wants all of the little piece bits and pieces of that movie for their own movie. So what are they going to do? They&#39;re going to hire all the people who were involved in that movie, like down to the cast and crew.</p>
                            <p>Dave (11:55.092)
                            I mean that&#39;s how Ken Faragula keeps his rent paid.</p>
                            <p>Bryan White (11:59.081)
                            Yeah. Yeah. This also features her. This was her. It was her. I think they were engaged at the time but but they were married after this Christopher Stone because after after this one ET she becomes known as D Wallace Stone whenever she was mentioned. It&#39;s D Wallace Stone. I call him in this great value Tom Atkins.</p>
                            <p>Dave (12:06.626)
                            They were engaged, they weren&#39;t married.</p>
                            <p>Dave (12:14.734)
                            That&#39;s right. Yeah, he is definitely a bargain bin, if I&#39;ve ever seen one. He&#39;s like every man.</p>
                            <p>Bryan White (12:25.829)
                            Yeah, he&#39;s not. I mean, they must have got him like two for one on it or something, because he&#39;s not terribly. He doesn&#39;t leave an impression. And the fact that every time I saw him, I saw Tom Atkins really kind of says something.</p>
                            <p>Dave (12:39.638)
                            And there&#39;s that part where like he gets out of bed and he has his shirt off and I&#39;m thinking, I don&#39;t think this is doing what they think it&#39;s doing.</p>
                            <p>Bryan White (12:47.962)
                            Yeah, yeah. But the two of them remained married until his death in the mid 90s. He had a heart attack, I guess. Also in this is Patrick McNeigh from the Avengers, the British one, that is. John Carradine, who, yeah, named from our last episode where we did the Sentinel. Robert B. Cook. Your favorite Dracula, which, okay.</p>
                            <p>Dave (13:05.058)
                            Yeah. Famously known as my favorite Dracula.</p>
                            <p>Bryan White (13:16.985)
                            This is also Robert Picardo&#39;s first feature. He&#39;d had a long run on Broadway before this. He was on Star Trek Voyager, I think.</p>
                            <p>Dave (13:23.018)
                            Was he on Star Trek?</p>
                            <p>Dave (13:27.146)
                            I feel like I&#39;ve seen him in everything, but I can only picture him in like Star Trek.</p>
                            <p>Bryan White (13:30.673)
                            I know. I know he&#39;s been in so much, but for whatever reason, I only think of him as that character. He&#39;s like a like a hologram. And I think it&#39;s Star Trek Voyager. And here&#39;s the thing. I&#39;ve watched like one episode of Star Trek Voyager, and yet that&#39;s the one I think of. Yep, when I think of him. But after this, like he&#39;s like a Joe Dante regular. He&#39;s in like nine Joe Dante movies.</p>
                            <p>Dave (13:42.774)
                            Yeah, I don&#39;t think I&#39;ve ever seen the show.</p>
                            <p>Dave (13:53.866)
                            Yeah, this cast is a huge cast. It is a weird cast. Slim fucking Pickens is in this movie, America&#39;s favorite rodeo clown, Slim Pickens. And that is not a joke. He was an actual rodeo clown.</p>
                            <p>Bryan White (13:57.577)
                            It&#39;s a really, really wild cast, but uh, slim pickings, slim pickets. And I got no, that&#39;s awesome. I got, and I got to, I got a note in here about him that I&#39;m sure we&#39;ll get to when they get to the colony. But, uh, also this is, so if you&#39;ve ever seen a Joe Dante movie, then you know that Dick Miller is in the movie. Um, and he always has a little cameo part or it&#39;s just a small part, but he&#39;s in like</p>
                            <p>Bryan White (14:25.949)
                            just about every Joe Dante movie. Yeah, yeah, he&#39;s he&#39;s he&#39;s yeah, because usually he just kind of shows up and he&#39;s got a line but like he&#39;s a guy that if you don&#39;t know who we&#39;re we&#39;re talking about by name, you definitely know him by face. You&#39;ve seen him in something. He plays a character that he always plays Walter Paisley. Walter Paisley is the name of the character that you played in Corman&#39;s movie Bucket of Blood and so for whatever reason, Joe Dante always casts him in a small part in his movies.</p>
                            <p>Dave (14:28.022)
                            He gets all- he&#39;s in this one for quite a bit actually. I was surprised by how much he&#39;s in it.</p>
                            <p>Dave (14:41.738)
                            and he always plays Walter Paisley.</p>
                            <p>Bryan White (14:56.613)
                            and his name is always Walter Paisley. Also of note for the cast and crew, Rob Boatine, who was Rick Baker&#39;s assistant at the time, did all of the basically did took the lead on special effects in this movie. Rick Baker was supposed to do the effects, but he got booked for American Werewolf at the same time and left Boatine sort of as a consolation prize. And then a year later.</p>
                            <p>Dave (15:21.256)
                            And I think we all know who wore it best.</p>
                            <p>Bryan White (15:24.477)
                            I&#39;m gonna say, because that was my next question. So a year later, Boutin really leaves his mark on the industry with the thing. But we&#39;re talking, we&#39;re not talking about the thing, we&#39;re talking about the howling. And of the two big werewolf movies of the year, which which one do you think? And I think you just answered my question, but which one do you think has the better wolves?</p>
                            <p>Dave (15:47.83)
                            I think they&#39;re just too, they&#39;re too different. I think, obviously the transformation scene in American Werewolf is iconic.</p>
                            <p>Bryan White (15:57.013)
                            Yeah, because I actually watched both of these movies back to back because I really wanted to have a little bit of perspective on it and</p>
                            <p>Dave (16:03.738)
                            I think these, the howling wolves look better far away. They don&#39;t look great up close. Well, some of them look good up close. Sometimes they look really silly. The transformation stuff can be real cheesy.</p>
                            <p>Bryan White (16:08.738)
                            Yeah.</p>
                            <p>Bryan White (16:11.246)
                            Oh, I love them up close. I think the wolf suit is outstanding.</p>
                            <p>Bryan White (16:21.253)
                            I love the transformations in this. Do you have, do you have like a, like, I&#39;m, in terms of like the classic monsters, I&#39;m really more of a Frankenstein guy, but like, I do love like werewolf movies. Do you have like a one, do you have one that you like, like more than any others?</p>
                            <p>Dave (16:37.566)
                            I love I mean I love werewolf movies in general. I&#39;m a big fan of the Wolfman, the original one. Yeah, I&#39;m a big fan of the was it Abbot and Castell meet Frankenstein, I think. I like that one a lot. I mean, the howling, I think, is probably of the later ones, probably the best. I saw one not that long ago.</p>
                            <p>Bryan White (16:44.365)
                            The original one? The universal, yeah.</p>
                            <p>Bryan White (16:51.913)
                            Oh yeah, that&#39;s, yep, yep, that&#39;s usually the other one everybody points to.</p>
                            <p>Dave (17:06.458)
                            I was gonna like Wolf Moon or something. It&#39;s about like a guy who he&#39;s a werewolf and he lives in like a housing tract and he&#39;s like just trying to keep to himself but this like kid figures out he&#39;s a werewolf. I can&#39;t remember what it was called. It was really good though. Yeah, it&#39;s such a hard one to pull off though. It always looks like shit.</p>
                            <p>Bryan White (17:21.714)
                            Oh yeah, um...</p>
                            <p>Bryan White (17:26.293)
                            You gotta yeah you really do like cuz you you have to do a transformation scene just because that&#39;s what everybody comes for.</p>
                            <p>Dave (17:34.25)
                            And it looks like they inevitably end up looking like muppets. I think that&#39;s the issue in The Howling is that there are certain angles where they just look like muppets. Especially that goddamn ending.</p>
                            <p>Bryan White (17:37.867)
                            Yeah.</p>
                            <p>Bryan White (17:42.777)
                            the the I know we&#39;ll talk about the we&#39;ll talk about the wolf at the end because that one is so weird. It&#39;s so weird. But I think if I had to if I have to pick one or the other, I I always pick the Howling. I like American Werewolf, but I feel like it&#39;s a different kind of movie and it definitely if as much as this one definitely feels like a Joe Dante movie, the other one also kind of feels like a like a John Landis</p>
                            <p>Dave (18:12.5)
                            Well, John Landis is not a good person, so. And that is, that is objectively speaking.</p>
                            <p>Bryan White (18:12.572)
                            Um.</p>
                            <p>Bryan White (18:17.595)
                            Oh, those poor children.</p>
                            <p>Bryan White (18:22.193)
                            Yeah. But yeah, also recently in sort of in terms of like recent werewolf movies, it&#39;s a lot of them fall back to too much on like CGI for my taste. But there&#39;s one called dog soldiers from like, I it&#39;s a little silly. It&#39;s bits, it&#39;s aliens with with werewolves, basically, but like, the wolf suits of that one.</p>
                            <p>Dave (18:35.83)
                            That Dog Soldiers, I was just about to say that that one&#39;s pretty good. The movie, the plot&#39;s a little bit of a slog, but.</p>
                            <p>Bryan White (18:47.709)
                            are like the best werewolf suits I&#39;ve ever seen. Like they&#39;re so good and they&#39;re and they&#39;re really conservative about showing them so that when you do get a good look at them they&#39;re really good. But a couple more notes before we really dive into it. This is based on a novel by a guy named Gary... me either. I found this out looking it up and not only is there just one novel there&#39;s like three or four of them by the same guy. Guy named Gary Brandner. But the movie apparently bears only a passing resemblance.</p>
                            <p>Dave (18:49.964)
                            Yeah.</p>
                            <p>Dave (19:02.102)
                            Yeah, I had no idea.</p>
                            <p>Bryan White (19:17.198)
                            If you watch the trailer for this movie on trailers from hell, which I mentioned, Josh Olson is the guy who does the sort of commentary on it. He remarks that he&#39;s not even sure if Joe Dante read the book before he made the book.</p>
                            <p>Bryan White (19:30.549)
                            And then this also, this bit also surprised me, kind of like when we were talking about Amityville, there are seven sequels to this movie. Did you know this?</p>
                            <p>Dave (19:40.135)
                            I know there- I can think of four.</p>
                            <p>Bryan White (19:42.257)
                            I know, because I top out at part three. That&#39;s the marsupials one. Yeah. I&#39;ve seen. Oh, I did not know this. I&#39;ve seen the second one and it&#39;s fucking stupid.</p>
                            <p>Dave (19:45.87)
                            Is that the one with the kangaroo?</p>
                            <p>Dave (19:51.15)
                            I&#39;ve seen a fourth one too.</p>
                            <p>Dave (19:57.208)
                            It is really, really terrible.</p>
                            <p>Bryan White (19:58.741)
                            It&#39;s really, really, really bad. The whole thing seems to hinge on the scene where Sybil Danning like rips her top off, because if you watch the movie all the way to the end, there&#39;s like this music video that plays over the over the credits where she does it like over and over and over again. So yeah, the sequels are I guess are all uniformly terrible. And there&#39;s a</p>
                            <p>Bryan White (20:26.553)
                            There&#39;s a remake supposedly in the works from the guy who directed it, the recent one. I know, I know. Oh yes I am, that would be one Pinot Denaggio!</p>
                            <p>Dave (20:30.35)
                            Well, that&#39;s not necessary. You&#39;re going to mention who did the music for this? Yeah. Pino Donaggio, my favorite film composer.</p>
                            <p>Bryan White (20:42.377)
                            He is great, man. Like, I mean, we were talking about this earlier. Like I, I&#39;m not super keyed in on him, but like I do, I do enjoy the soundtrack to Tourist Trap a lot. It&#39;s really, really spooky. It&#39;s very, very effective. And I really love this one too. There&#39;s a ton of like, there&#39;s a lot of very conventional like horror movie scoring with a lot of like very sharp.</p>
                            <p>Bryan White (21:10.289)
                            stabby strings like you might find in like a Manfredini score, but there&#39;s also a ton of like pipe organ.</p>
                            <p>Dave (21:17.18)
                            Which is weird for him because he is, although he has done a lot of horror, he&#39;s a pretty traditional composer. You know, he wrote that, I think it&#39;s, he wrote the, you don&#39;t have to say you love me that Dusty Springfield song. He wrote that. But yeah, I mean, he did like Carrie, Don&#39;t Look Now. I think he did that Dario Argento movie Trauma.</p>
                            <p>Bryan White (21:25.729)
                            Yeah. Yeah.</p>
                            <p>Bryan White (21:32.597)
                            Did not know that. God damn.</p>
                            <p>Bryan White (21:41.177)
                            Oh yeah.</p>
                            <p>Dave (21:41.582)
                            I mean, he&#39;s got a shitload of stuff, like a ton of De Palma stuff, but it&#39;s mostly really like pretty traditional strings, which is why these like weird kind of droning warbling noises throughout this score are pretty atypical for him. But he kills it.</p>
                            <p>Bryan White (21:44.941)
                            Mm-hmm.</p>
                            <p>Bryan White (21:53.848)
                            Yeah.</p>
                            <p>Bryan White (21:57.853)
                            right? And there are a lot of there are a lot of like weird moments because I like I didn&#39;t really notice the first time. It was really the second time that I went through when I was taking notes when I was really sort of paying attention to sort of all the details of the movie and there are a lot of like story beats where it&#39;s just it&#39;s not silent though it feels silent but there&#39;s a lot of like just noise.</p>
                            <p>Bryan White (22:24.065)
                            that was like very good that was deliberately composed for the scene. It&#39;s. Right, it&#39;s it&#39;s it&#39;s great. It&#39;s outstanding. So of all the sequels released in 1981, this was the first one to be released. It had a budget of one and a half million dollars. And its box office growths was almost 18 million. So.</p>
                            <p>Dave (22:27.318)
                            Yeah, like non-diegetic noise.</p>
                            <p>Bryan White (22:44.533)
                            You know, for 1981, when there&#39;s not like 3000 movie theaters in the United States, that&#39;s really, really good. Also, one of the most evocative movie posters of any horror movie that I can think of.</p>
                            <p>Dave (22:54.864)
                            OK, so that I got to jump in on that one, because this is the of the VHS boxes. This was the one that I remember more than most, I think, because the the art doesn&#39;t make any sense and it&#39;s very unclear what&#39;s going on. Like, it looks like a corpse kind of ripping through something.</p>
                            <p>Bryan White (23:13.546)
                            Yeah.</p>
                            <p>Bryan White (23:16.625)
                            Well, all you see is just yeah, you just see the fingers and the tearing and then like a screaming mouth There&#39;s absolutely nothing Wolfy about it um, yeah</p>
                            <p>Dave (23:25.93)
                            Yeah, and I&#39;m sure Joe Dante had nothing to do with it, but it is such a shitty cover. And yeah, I mean, it&#39;s iconic, but it just it doesn&#39;t make any sense. It does not represent the movie at all.</p>
                            <p>Bryan White (23:29.822)
                            Oh yeah, yeah.</p>
                            <p>Bryan White (23:33.209)
                            I think it&#39;s great. Oh my God, like.</p>
                            <p>Bryan White (23:39.529)
                            No, no. But like, I remember every time we would go to like a video store or something like that, like particularly like when we were little, you know, we were kids and like that when that stuff still kind of spooked me, like there are certain there&#39;s certain box art that really stayed with me. And like this was one of them. Like I remember like there was there were a couple of stores that were near us that had posters of this up. And it just, I don&#39;t know, it really it spoke to me in a way that like others didn&#39;t, you know, like it&#39;s it suggested something because</p>
                            <p>Bryan White (24:09.317)
                            It does like this and all the marketing for it, of course, is like this is the most terrifying movie you will see this year, but it&#39;s not really like that. It&#39;s actually kind of a comedy. But they were really going for it in that same way and like all the marketing it really kind of spooked me kind of in the same way that like I saw I remember seeing the Friday the 13th poster when like dad took me to see the Empire Strikes Back. That&#39;s a great poster again like</p>
                            <p>Dave (24:17.363)
                            No.</p>
                            <p>Dave (24:33.258)
                            And that&#39;s a bad ass poster.</p>
                            <p>Bryan White (24:37.045)
                            doesn&#39;t really tell you what you&#39;re, like, what the movie&#39;s about, but like, it leaves a fucking mark.</p>
                            <p>Dave (24:42.07)
                            Yeah, that is some economical shit because it is just a dude standing there with a knife.</p>
                            <p>Bryan White (24:46.965)
                            Yeah. Yeah. So, let&#39;s get into a TV studio.</p>
                            <p>Dave (24:53.566)
                            Oh, hey, wait, wait, a quick question. Is this the same place they shot the Goonies?</p>
                            <p>Bryan White (24:59.933)
                            You know, it might be. I don&#39;t know. I know. It&#39;s so that&#39;s this is all shot in Mendocino County, County, which is sort of a coastal county in northern California. So it&#39;s up above it&#39;s up above San Francisco. It might actually be like the same area, because it definitely does have that kind of geography. And it doesn&#39;t it&#39;s in California, but it doesn&#39;t feel like California, because like whenever they do California movies, it&#39;s always Los Angeles.</p>
                            <p>Dave (25:01.398)
                            Those beach scenes look like the Goonies.</p>
                            <p>Dave (25:29.326)
                            I&#39;m gonna ask the internet, the internet will know.</p>
                            <p>Bryan White (25:31.881)
                            Yeah, you look that up. I&#39;m gonna get into this. So we open up on a TV studio when Patrick McNeese is on like a talk show. He&#39;s pitching what sounds like a self-help book. There&#39;s a lot of like flashing back and forth as we&#39;re trying to sort of establish several little motifs. So there&#39;s real close up, quick flashes of Picardo, like mostly his mouth as his character, Eddie Quist. He&#39;s in his apartment. Eddie is a serial killer.</p>
                            <p>Bryan White (26:02.317)
                            D Wallace is on the street going to meet him while back at the studio, they&#39;re sort of queuing up what looks like some sort of like on the street reporting as they&#39;re trying to track down this killer who kind of has got the city in the grips of terror.</p>
                            <p>Dave (26:18.922)
                            This is where you get Kevin McCarthy as the studio head or something.</p>
                            <p>Bryan White (26:23.645)
                            Yeah, yeah. So a little later on, I got notes about him, but I will mention him. So Kevin McCarthy, he plays the station manager in this. And if you&#39;ve</p>
                            <p>Dave (26:31.958)
                            He has been 100 years old forever.</p>
                            <p>Bryan White (26:34.949)
                            I know he&#39;s even like even so his original if you know Kevin McCarthy at all. It&#39;s probably from invasion of the body snatchers the original one. He&#39;s the like the main guy the one who everybody seen the scene at the end where he&#39;s running around like freaking out in traffic. That&#39;s that&#39;s him. So he plays basically a dick in this movie. He&#39;s just a gigantic jerk. He&#39;s the station manager.</p>
                            <p>Bryan White (27:00.445)
                            And if you&#39;ve ever seen the weird owl movie UHF, he&#39;s basically the exact same character in that one, only a little bit more of a fascist.</p>
                            <p>Dave (27:07.382)
                            Well, he&#39;s also in Piranha and he plays a similarly terrible person.</p>
                            <p>Bryan White (27:13.498)
                            Yeah, I mean, I wonder if like that was like when weird Al was like, we got to get a station manager. Get me the guy from from the howling and the invasion of body statues. But the next one of the first things that happened, it jumped out. It grabbed me and I had to write a note. So DeWalls, her character&#39;s name is Karen White. And she&#39;s like an anchor for the local news. And she&#39;s on the street going to meet Eddie Quist when she runs into a guy in an alley who sticks out his leg and asks how much for a half and half?</p>
                            <p>Bryan White (27:41.717)
                            And this takes me back to our fucking maniac episode, which is like, what is this street language that everybody is speaking? Because in that one, the hookers are talking about like the ultimate and around the world. And I&#39;m like, what is a half and half? Like a half and half what? This is like, like, could I just go up to somebody and be like, hey, give me a double double animal style. You know, like what, what the fuck is this?</p>
                            <p>Dave (28:04.297)
                            These are like the fucking jokes that I would make on the fly.</p>
                            <p>Bryan White (28:07.648)
                            I&#39;m out.</p>
                            <p>Bryan White (28:10.189)
                            Oh, it&#39;s just it&#39;s I couldn&#39;t I couldn&#39;t resist it because like we just talked about this. But she&#39;s supposed to go to like a phone booth and give him a call and then he&#39;s going to tell her where to meet him and his whole thing is he leaves a calling card everywhere he goes like whenever he kills somebody and it&#39;s a smiley face sticker. So you&#39;re gonna see smiley face stickers wherever it is that he&#39;s like around.</p>
                            <p>Bryan White (28:37.625)
                            and so she&#39;s wandering down the sleazy sunset strip. She goes into a phone booth, picks up the phone, starts calling, and there&#39;s a guy outside who&#39;s waiting.</p>
                            <p>Dave (28:49.926)
                            Now, I will say before you say who that guy is, I&#39;m going to, I have not looked this up, I could be totally wrong. I think this is a nod to the William Castle cameo in Rosemary&#39;s Baby.</p>
                            <p>Bryan White (29:03.081)
                            it&#39;s it absolutely is. It 100% is. Um because and and honestly, okay, so it is not 100% is. It just it probably very much is and we it&#39;s the same setup but also Dante made the movie Matinee which isn&#39;t about Castle but it&#39;s about a guy like Castle, right? So, he&#39;s obviously a fan and like this was like this was his big like</p>
                            <p>Dave (29:14.414)
                            I mean, it&#39;s like the same exact scene.</p>
                            <p>Bryan White (29:31.813)
                            Also, like, this is the guy who gave him a start. He puts him in the movie and the whole thing. But yeah, that&#39;s Roger Corman who waits outside. So once she gets the call and is told where to go, she goes out and is like, all yours, mister, and then he goes in. And yeah, there it is. It&#39;s Roger Corman.</p>
                            <p>Dave (29:49.902)
                            It&#39;s a really weird structure. This, I mean, the whole movie is really weirdly structured, but just to be dropped into, there&#39;s like this kind of, your program is already in progress type of thing. We&#39;re like, we don&#39;t have any of the backstory or any context. It&#39;s just like, here&#39;s the news lady. And also she&#39;s hunting a serial killer because for some reason the police won&#39;t, I don&#39;t know.</p>
                            <p>Bryan White (30:00.842)
                            Yeah.</p>
                            <p>Bryan White (30:12.885)
                            Yeah, I actually when I was when I was trying to take the notes for this I as I was writing this I realized I was going to have a really hard time sort of doing this sort of like scene to scene breakdown that we usually do because there&#39;s this movie has a lot of like jumping back and forth. And there&#39;s a lot of cuts like it&#39;s not a steady a movie with a lot of sort of like steady scenery and it&#39;s not a. This happens then this happens then this happens we&#39;re constantly like shooting back between.</p>
                            <p>Bryan White (30:40.217)
                            street and then like there&#39;s some police who are supposed to be like an escort for her and then we go back to the studio and then we go back to her and then we go back to you know this and it&#39;s it&#39;s and it&#39;s like that throughout the movie like even as things</p>
                            <p>Dave (30:53.534)
                            It&#39;s also a weird mix of like, actual landscapes and then like soundstage stuff.</p>
                            <p>Bryan White (31:00.501)
                            Yeah, yeah, because I mean, this really is like this really is the the Sunset Strip and the porno store that she goes into in the next scene is like a real porno store, like they didn&#39;t dress it up or anything like that. They just shot in one of like the sleazy spots on the strip. But before we even get to there, there&#39;s this really this is really funny scene where it cuts to one of the anchorman in a restroom and he&#39;s like.</p>
                            <p>Bryan White (31:28.437)
                            He&#39;s like reading his copy for the night, and he&#39;s sort of rehearsing his speech for it. I love it because what he&#39;s doing, and I noticed this, I&#39;ve seen this movie a thousand times, but I&#39;ve only noticed it this time, probably because I was really paying attention. But when you see it, he&#39;s addressing the camera directly, but the camera is like slowly pulling back as he does this. And you realize that,</p>
                            <p>Dave (31:36.513)
                            It is a scene out of like Anchorman.</p>
                            <p>Dave (31:53.778)
                            I&#39;m, I gotta abandon, I&#39;m abandoning the pickle beer. I can&#39;t do this.</p>
                            <p>Bryan White (31:56.994)
                            Ha ha!</p>
                            <p>Bryan White (31:59.469)
                            You gotta get you something more conventional.</p>
                            <p>Dave (32:00.862)
                            Holy shit. Alright, go on.</p>
                            <p>Bryan White (32:03.369)
                            Yeah, but the camera is slowly pulling back and we realize that he&#39;s speaking into a mirror, but he&#39;s like looking at the camera&#39;s reflection in the mirror as he does it. But then Great Value Tom Atkins comes in, and he kind of composes himself before returning, you know, and he tries to talk to him and he realized that in spite of his like anchorman voice, he&#39;s got like a twangy like southern accent.</p>
                            <p>Bryan White (32:30.017)
                            And then as soon as Tom Atkins leaves the scene, he sort of composes himself and goes back into it. He sort of like goes back to addressing the camera. There&#39;s no reason for him to do it, but it&#39;s just, it&#39;s a really neat touch in terms of filmmaking. I thought that was really funny. [&quot;The Last Supper&quot;]</p>
                            <p>Dave (32:44.891)
                            There&#39;s a lot of that shit in this movie. There&#39;s a lot of that kind of like nudge nudge, wink wink, aren&#39;t people funny?</p>
                            <p>Bryan White (32:50.485)
                            Yeah, yeah. But.</p>
                            <p>Dave (32:52.566)
                            But it&#39;s never mocking, it&#39;s never cruel, it&#39;s always kind of done with like, it&#39;s all in good fun.</p>
                            <p>Bryan White (32:57.673)
                            I think he was one of the first people to really do that. You know, like nobody, this wasn&#39;t really until like the Spielbergs came along, like people and like John Carpenter does this a little bit too, though he&#39;s not as, he doesn&#39;t lay it on quite as thick as Joe Dante does, but like this was probably the first generation of filmmakers who really grew up on Hollywood and like.</p>
                            <p>Dave (33:22.45)
                            and who weren&#39;t shy about select those their self-awareness.</p>
                            <p>Bryan White (33:26.249)
                            Yeah, because like in the 90s, you would get like Tarantino and Kevin Smith and like Robert Rodriguez, who spared no opportunity to just name drop movies that they fucking love and like reference them left and right. But like this was really kind of like the first wave of that. And it doesn&#39;t feel.</p>
                            <p>Dave (33:45.758)
                            And it gives it a really weird tone. Like this movie is all over the place in terms of tone, but it never feels inconsistent. It&#39;s like you&#39;re watching this grimy shit on the screen, but there&#39;s also like this kind of whimsy to it. That&#39;s really interesting.</p>
                            <p>Bryan White (33:53.078)
                            Yeah.</p>
                            <p>Bryan White (34:00.317)
                            Yeah, yeah, I think that&#39;s what I like, because I gotta admit, like I do love when a movie&#39;s like, hey, I&#39;ve seen this movie too, right? Like here&#39;s me like signaling to you. Like I, it&#39;s corny, but I love it.</p>
                            <p>Dave (34:14.442)
                            Like, hey, aren&#39;t porn stores funny?</p>
                            <p>Bryan White (34:17.855)
                            Not this one, this one is gross.</p>
                            <p>Dave (34:20.262)
                            And I mean, I agree on both fronts. They are funny and gross.</p>
                            <p>Bryan White (34:23.161)
                            Yeah, so she goes into the porno store she&#39;s been instructed to go to and every time she like walks by like one of the customers they just like hurriedly throw their shit back on the rack and like leave very what yeah they&#39;re like oh no shit a lady but uh she she goes into the into the back where the booths are and sort of goes in</p>
                            <p>Dave (34:36.138)
                            Yeah, I love that. That shit where they&#39;re like, they&#39;re all skinnish and they all just kind of run out.</p>
                            <p>Dave (34:47.498)
                            Which proceeds, so that fun winzy of a, you know, oh shit a lady, proceeds what is one of the sleaziest moments of probably any 80s movie I&#39;ve ever seen.</p>
                            <p>Bryan White (34:52.513)
                            Yeah.</p>
                            <p>Bryan White (34:58.677)
                            Yeah. Well, yeah, yeah, like, what is it? Video drums got a little bit of this too, in it.</p>
                            <p>Dave (35:06.102)
                            But it has like a last house on the left kind of edginess that I&#39;m like, I don&#39;t like any of this.</p>
                            <p>Bryan White (35:12.929)
                            Right, because it&#39;s not just like, because she goes in and she sits down and then Eddie Quist, the guy that she&#39;s looking for, comes in behind her, pops a coin in the machine, the movie starts, and it&#39;s not just like a porno movie, it&#39;s like a rapey porno movie.</p>
                            <p>Dave (35:26.658)
                            Well, so he says something really weird. He says like, you know, he&#39;s kind of whispering in her ear and doing whatever. And then he says, she didn&#39;t feel a thing. Is he sort of just like projecting or is this supposed to be him?</p>
                            <p>Bryan White (35:33.689)
                            Mm.</p>
                            <p>Bryan White (35:41.819)
                            Right, because I don&#39;t I don&#39;t know because they&#39;re</p>
                            <p>Dave (35:44.042)
                            Because if it&#39;s supposed to be him, there&#39;s a lot of unanswered questions. Like, who put the film on the thing?</p>
                            <p>Bryan White (35:49.097)
                            Yeah.</p>
                            <p>Bryan White (35:51.865)
                            know. There&#39;s a little, well, there&#39;s also a little bit of a lead in that, I think it gets lost in the kind of constant shuffle of like scenery and stuff you&#39;re seeing when they go back to the to the TV studio, because there&#39;s a report of one of Quist&#39;s victims that her husband because her husband&#39;s also there, while the police are there kind of like monitoring, she&#39;s wearing a wire and stuff like that. So he&#39;s there as just, you know, he&#39;s worried that his wife&#39;s</p>
                            <p>Bryan White (36:20.673)
                            going to meet a serial killer. And like in the process of that, they sort of reveal that like one of the, they have footage of one of the victims. So like maybe they&#39;re talking about that victim or maybe he&#39;s actually talking about the woman in the movie that&#39;s watching. But yeah, there&#39;s a little, there&#39;s a lot of that. He&#39;s, they&#39;re, you know, these people, they don&#39;t have, they have dead eyes. They got, they don&#39;t have souls, not like you.</p>
                            <p>Dave (36:45.346)
                            It sort of hints, it&#39;s more of that like disorientation. Like there&#39;s so much shit you don&#39;t know because you&#39;ve just been dropped in at the end of the story.</p>
                            <p>Bryan White (36:55.797)
                            Yeah, yeah, like this is something that&#39;s been ongoing for a while.</p>
                            <p>Bryan White (37:02.473)
                            And then she eventually like he&#39;s talking, he kind of monologues a little bit. And then he sort of steps back and his voice drops. It&#39;s it&#39;s being modulated. He&#39;s like, all right, you can turn around now, Karen. And he&#39;s but he&#39;s like completely obscured in the glare of like the projector.</p>
                            <p>Bryan White (37:22.965)
                            So, we don&#39;t really see what&#39;s going on. We just catch a little bit of his head in the glare. And something is definitely not right. But then, he like attacks her. And there&#39;s like a lot of these quick cuts of like her in peril and it&#39;s not really sure what it&#39;s not really clear what&#39;s happening to her in the moment. But as this is happening, sorry.</p>
                            <p>Dave (37:46.177)
                            And then like, like, like two white Southern cops, they just start firing into public.</p>
                            <p>Bryan White (37:53.001)
                            Yeah, they don&#39;t even open the door. The guy just shoots through the door like he could have he could have hit her, you know, like Good shooting but yeah, they they take out presumably they take out Eddie Quist and not her They lead her out into the street. The police are there. It&#39;s a whole to do Um, but what we learn is that her like this was this was understandably a very traumatic experience, right? so um</p>
                            <p>Dave (38:18.806)
                            Well, you get that scene, this other scene again, that kind of compounds that, that kind of tension and disorientation when she&#39;s outside and they&#39;re talking to her. Yeah, they&#39;re just flashing across men. Like there&#39;s some women in there, but it&#39;s very clear that he&#39;s flashing across men&#39;s faces. And it&#39;s sort of like, she&#39;s wondering if there&#39;s menace outside as well, just like quickly looking at men.</p>
                            <p>Bryan White (38:26.389)
                            and they&#39;re flashing across</p>
                            <p>Bryan White (38:40.853)
                            Yeah, and there may as well have, there might as well be, cause like these people have no expression whatsoever, like just dead eyes.</p>
                            <p>Dave (38:51.81)
                            They all want a half and half.</p>
                            <p>Bryan White (38:55.177)
                            and the ultimate. Yeah. But then we from here we cut to Karen and Bill&#39;s place. And this was just to kind of give us a I don&#39;t know just a sense of their relationship. You know they&#39;re very close. The one.</p>
                            <p>Dave (38:56.066)
                            Now why else would you be there?</p>
                            <p>Dave (39:17.166)
                            I think this is actually a really important scene though, because it&#39;s throughout this movie, well, there&#39;s a shift in his character, which you&#39;ll get to eventually, but like this is 1981 coming off of like hyper masculine 70s. And this movie I think has a lot to do with masculinity, but coming off of that, he could have been portrayed as Tom Atkins, like as this ridiculous cartoon, but he&#39;s not. And he&#39;s, you know, he&#39;s vegetarian.</p>
                            <p>Bryan White (39:26.998)
                            Yeah.</p>
                            <p>Bryan White (39:42.345)
                            Yeah, that&#39;s.</p>
                            <p>Dave (39:46.326)
                            He is responsive to her needs. He&#39;s kind of sensitive and he&#39;s never mocked for it. He&#39;s never, they never make a joke about it. He&#39;s always just like a new kind of guy.</p>
                            <p>Bryan White (39:53.821)
                            Oh, right. Yeah. Cuz he definitely, yeah, he definitely like signals to other people and nobody is like, oh, you don&#39;t eat meat, huh? Like, that that is that is definitely a valid point cuz a lot of a lot of the undercurrent to this movie is this is really the beginning of what you might call like the New Age movement and there&#39;s a lot of</p>
                            <p>Dave (40:01.122)
                            Yeah.</p>
                            <p>Dave (40:14.186)
                            It&#39;s actually a little bit, it&#39;s quite a ways into that movement. This is a kind of a response to that, that like back to nature thing, that like what deliverance is about. Deliverance is about the getting men back to nature and getting in touch with your inner whatever. And that&#39;s what deliverance is a direct response to that movement. And this is too, where it&#39;s like, you get this urban grit for the first 40 minutes or so, and then it&#39;s juxtaposed with this like kind of</p>
                            <p>Bryan White (40:20.617)
                            Right, because the whole movie... Yeah.</p>
                            <p>Bryan White (40:30.39)
                            Yeah.</p>
                            <p>Bryan White (40:34.102)
                            No shit.</p>
                            <p>Dave (40:44.114)
                            back to nature cabin, camp, like summer camp for adults type of bullshit. And it&#39;s really interesting because it&#39;s it&#39;s all done very subtly and very well. And none of it ever feels really forced. Like, there&#39;s no agenda to this.</p>
                            <p>Bryan White (40:50.274)
                            Yeah.</p>
                            <p>Bryan White (40:57.321)
                            Yeah, yeah, because this was definitely this was the time. This was like really this was the like, I mean, the human potential new age thing. It definitely had its roots in the 70s, obviously, and even really even a little bit before that, really, in the 60s. But like this is when it had really kind of become commodified and you started to find self-help books all over the place. And there was a lot of this talk about like men being more sensitive and and that sort of thing. And.</p>
                            <p>Dave (41:20.45)
                            Well, you also get the urban decay, like all the, you know, in major cities around the country, you get those discussions about urban decay and about redevelopment, like, you know, white people fleeing the city because it&#39;s, you know, a den of iniquity. And then you, most of them wanna go back to something more pure or whatever. That&#39;s, I think that&#39;s what&#39;s represented here because Eddie and the whole first act of the movie are really about urban decay and kind of moral decline.</p>
                            <p>Bryan White (41:27.059)
                            Oh sure.</p>
                            <p>Bryan White (41:39.307)
                            Yeah.</p>
                            <p>Bryan White (41:48.777)
                            Yeah. Yeah. Yeah. Because it&#39;s sort of like how we were talking about in Maniac where this is also the same period where whenever anybody wanted to sort of signal like the urban life is like the city is a jungle and it&#39;s nothing but predators all over the place and everything whenever they did that they really they always set a movie in Manhattan because like this was like all time low for New York City. Well this one is set in Los Angeles.</p>
                            <p>Bryan White (42:18.057)
                            And it does the same sort of thing where like Los Angeles is a is a pastiche of of like different sort of vibes and looks and everything like that. But as far as this movie is concerned, all they want you to know is that Los Angeles is the sunset strip at midnight.</p>
                            <p>Dave (42:35.565)
                            It&#39;s all like the lead up to a porno.</p>
                            <p>Bryan White (42:38.542)
                            Yep. Yeah.</p>
                            <p>Dave (42:39.882)
                            And I&#39;ll tell you right now, I live in a city and the biggest threat to me are skinny white guys with mustaches.</p>
                            <p>Bryan White (42:47.841)
                            Yeah, you can&#39;t you can&#39;t trust them. No, but it&#39;s it is like this. This is a sort of establishing scene to sort of really let you know what the nature of their relationship is, and it is a very healthy and loving relationship. They seem to be like. You know, well matched and they are, you know they&#39;re they&#39;re caring and it&#39;s it&#39;s, you know, it really it really sets him up because what happens toward the end of the movie is essentially a breakdown of all of that.</p>
                            <p>Bryan White (43:18.721)
                            but something to note also, because we&#39;re about to meet a whole bunch of characters. A lot of the characters in this movie are named after directors of other werewolf movies. So Karen&#39;s husband is a guy named Bill Neal. And Bill Neal is the director of Frankenstein Meets the Wolf Man. Patrick McNea plays a character named Dr. Wagner, and he&#39;s named after George Wagner, who directed the original Wolf Man for Universal. Terry.</p>
                            <p>Bryan White (43:46.445)
                            Karen&#39;s friend is named Terry Fisher, though I don&#39;t think they ever actually say her last name in the movie, but she&#39;s named for Terrence Fisher, who did Curse of the Werewolf for Hammer. And there&#39;s like a shitload of others. There&#39;s even a Paul Nash reference in it. Yeah, but I, again, like I was looking for it, I never heard it.</p>
                            <p>Dave (44:01.891)
                            Really?</p>
                            <p>Dave (44:06.918)
                            One of the guys, the guy when they go to the morgue, he mentions someone, Stuart something, that&#39;s another one of the directors.</p>
                            <p>Bryan White (44:13.749)
                            Yeah, but like this movie is also loaded with other like cute little little nods and shit all over the place. But but yeah, somebody in this character&#39;s name is Jacinto Molina, which is Paul Nash&#39;s real name.</p>
                            <p>Bryan White (44:27.605)
                            But we have, let&#39;s see, is it? No, right, so next we we are introduced to Terry and Chris, who are I love him too. They&#39;re really awesome. They&#39;re really funny.</p>
                            <p>Dave (44:37.299)
                            Love this couple.</p>
                            <p>Dave (44:41.198)
                            This is some Nancy Drew shit and I think they&#39;re great.</p>
                            <p>Bryan White (44:43.905)
                            They&#39;re the best. They&#39;re the best. And most of it, I found this out while I was watching it. I had a little bit of the director commentary track on. A lot of these scenes with them were not in the original script. A producer would come along and be like, I don&#39;t think anybody&#39;s gonna be able to follow this movie. You need to like do some exposition. And so they would like throw these scenes together involving these two in order to kind of move the plot along and kind of keep you on track.</p>
                            <p>Bryan White (45:11.121)
                            And so in the in the first one where we meet them, they go to Eddie Quist&#39;s apartment. They&#39;re hilarious. No, they&#39;re the best. They&#39;re the best. So when they get there, they find the places like it&#39;s littered with like animal bones and a lot of drawings. Apparently, he&#39;s a really good artist. And</p>
                            <p>Dave (45:15.702)
                            They&#39;ve got great chemistry too. Like you couldn&#39;t pick better people.</p>
                            <p>Dave (45:32.922)
                            Okay, is he though? Because a lot of this art has like a doodling on your history book in class type of vibe to it.</p>
                            <p>Bryan White (45:35.234)
                            I think he&#39;s a fantastic artist and</p>
                            <p>Bryan White (45:44.405)
                            I wish I could draw like that is what I think I&#39;m really saying here.</p>
                            <p>Dave (45:47.262)
                            I think you can draw like that.</p>
                            <p>Bryan White (45:50.285)
                            Definitely cannot. But there&#39;s also.</p>
                            <p>Dave (45:52.29)
                            There&#39;s one like when they go to the house, his sister&#39;s house, and it&#39;s like on the wall, and it&#39;s like, okay, the same person did not do this art.</p>
                            <p>Bryan White (46:00.697)
                            which is funny because you&#39;re supposed to see it and go wait a minute but yeah I mean it&#39;s it&#39;s true like it&#39;s a serial killer&#39;s apartment because there&#39;s also like clippings of like crime reports from newspapers all over the wall and some of</p>
                            <p>Dave (46:03.61)
                            Mm-hmm.</p>
                            <p>Dave (46:14.358)
                            But also crime reports, like really old crimes from like the thirties. And it&#39;s like, are you just an enthusiast as well? Cause there&#39;s one that&#39;s like something about the sailor&#39;s wife or something. It&#39;s like, it&#39;s, it&#39;s something really crass, but yeah, it&#39;s like, this clearly is something that happened a long time ago that he is obviously, or maybe he was involved cause they&#39;re werewolves. They live forever or something. I don&#39;t know, but I was like, I saw that and I was like, wait a minute, she&#39;s just a collector.</p>
                            <p>Bryan White (46:21.43)
                            Oh, is he?</p>
                            <p>Bryan White (46:27.597)
                            That&#39;s later on.</p>
                            <p>Bryan White (46:36.169)
                            Yeah. Yeah, yeah.</p>
                            <p>Bryan White (46:41.089)
                            But like, here&#39;s a little taste of some of the clippings on the wall. LA baby left unattended just three minutes. Too many rapes to count. Pieces of Tracy&#39;s scalp clung to the tire iron. And that&#39;s a headline. And death by man-eating piranhas.</p>
                            <p>Dave (46:56.278)
                            That&#39;s a headline?</p>
                            <p>Bryan White (47:03.477)
                            Yep. So you&#39;ll get a little reference to the to Piranha there.</p>
                            <p>Dave (47:09.006)
                            I mean, it&#39;s any chance. It&#39;s just like, hey guys, remember when I made other movies too?</p>
                            <p>Bryan White (47:12.77)
                            Remember this movie I made three years ago? Yeah. So we move forward a little bit and they&#39;re going to put her back on the news. Just as in a regular spot because you know, it&#39;s been a couple of days. She got a traumatic experience and they gave her time off. So she&#39;s back into the swing of things and she goes on and freezes up.</p>
                            <p>Dave (47:14.926)
                            That was fun. Wasn&#39;t that fun when I did that?</p>
                            <p>Bryan White (47:35.449)
                            and they very very quickly cut away and they replaced the segment with just whatever Kevin McCarthy&#39;s character&#39;s name is.</p>
                            <p>Dave (47:43.702)
                            Yeah, Kevin McCarthy is kind of like public moralizing rant.</p>
                            <p>Bryan White (47:47.145)
                            Yeah, talking about our culture of violence in this country. It&#39;s basically like Tucker Carlson bullshit. But hey, this is 1981 and it&#39;s a movie about trauma. Suck it, A24. Yep. So the thing is, is we they they they get her away. They send her to Dr. Wagner because he&#39;s just I don&#39;t know, he&#39;s there in the studio or something like that. They have a connection to him.</p>
                            <p>Dave (48:02.231)
                            That&#39;s right.</p>
                            <p>Bryan White (48:17.201)
                            and he decides that he&#39;s gonna send her up.</p>
                            <p>Dave (48:18.294)
                            Yeah, he&#39;s like they&#39;re talking head on any quest. Like he&#39;s he&#39;s giving commentary about serial killers.</p>
                            <p>Bryan White (48:25.089)
                            Yeah, yeah. And he decides he&#39;s going to send her up to the colony, which is this, which is the name of his like therapy retreat. And so we get a little bit of a driving scene just to kind of set you up for it. Yeah, that&#39;s that&#39;s yeah there&#39;s a lot of a lot of old people there. And one of the things Karen says is, I hope these people aren&#39;t too weird, and it immediately cuts to John Carrotine. John Carrotine fresh off our last movie.</p>
                            <p>Dave (48:38.414)
                            It&#39;s like Nexium, but less sexy.</p>
                            <p>Bryan White (48:54.381)
                            the sentinel and somehow looking bet.</p>
                            <p>Dave (48:56.247)
                            Speaking of people looking like they&#39;ve always been a hundred.</p>
                            <p>Bryan White (48:59.813)
                            Yeah, I was gonna say he&#39;s somehow looking better, and this is four years after the Sentinel, but he&#39;s on the beach there howling with joy in spite of the scene that happens moments later, where he seems poised to throw himself on the fire to end it all. So like this guy is bipolar or something.</p>
                            <p>Bryan White (49:20.109)
                            But yeah, so one of the things I wanted to mention, I might have mentioned it when we were talking about it, but this is again, I said this shot up in Mendocino County. There&#39;s a really great episode of Horrors Hallowed Grounds on YouTube that you can you can look up where the host there, Sean Clark, he goes on a big drives up there, goes on a tour of all the locations up there. I&#39;ll link it in the show notes. But yeah, this is a whole like menagerie of just weird people.</p>
                            <p>Bryan White (49:49.093)
                            and we are introduced to Slim Pickens who comes comes shouting into the frame. I&#39;m not even sure if it&#39;s possible for him to speak at room tone, like the yelling Texas maniac seems to be his brand.</p>
                            <p>Dave (49:51.682)
                            Fuck yeah.</p>
                            <p>Dave (50:02.198)
                            I don&#39;t, did you explain who Slim Pickens is? Because I&#39;m guessing he is probably no longer a household name.</p>
                            <p>Bryan White (50:08.997)
                            Yeah, it&#39;s probably been a little while. I&#39;ll like he&#39;s in Dr. Strangelove. That&#39;s where I know. Well, that&#39;s where everybody knows him from. Oh, well, yeah, but he was in a shitload of movies.</p>
                            <p>Dave (50:16.27)
                            I&#39;ve always been placing saddles, too.</p>
                            <p>Dave (50:20.362)
                            He is also in This House Possessed, a fantastic television film from, I think, this same year.</p>
                            <p>Bryan White (50:26.145)
                            damn. Yeah, but he was he was immortalized on the Simpsons is like the Texas Oil Man character. Yeah, but that&#39;s that&#39;s him. He&#39;s and this is like basically he&#39;s got one gear and he does it really well. And so this is him in every movie. He&#39;s just the Yelly Texas guy. And after seeing this and like watching it basically again, it sort of struck me for the first time. Are all of these</p>
                            <p>Bryan White (50:54.421)
                            Are all of these people supposed to be werewolves? Okay, okay. Cause...</p>
                            <p>Dave (50:56.854)
                            Yes.</p>
                            <p>Dave (51:01.39)
                            because they&#39;re all in the scene at the end.</p>
                            <p>Bryan White (51:04.309)
                            Well, there&#39;s a lot of people at this. This is a beach party that we&#39;re talking about here. There&#39;s a lot of people in this scene.</p>
                            <p>Dave (51:09.874)
                            And like, we&#39;re talking like, I know what you did last summer level beach party. Like they are a stone&#39;s throw from playing volleyball. This is like this is some real white people shit.</p>
                            <p>Bryan White (51:17.72)
                            Yeah.</p>
                            <p>Bryan White (51:21.077)
                            Yeah, they&#39;re like cooking prime rib, like a huge cut of beef, like right over an open flame. Like there&#39;s a shitload of people. There&#39;s like there&#39;s there&#39;s a like a like a what do you call it? A bluegrass band playing like it&#39;s.</p>
                            <p>Dave (51:33.334)
                            Yeah, they&#39;re all weird stereotypes, except just like Bill or these other characters. They&#39;re not treated as the butt of a joke. They&#39;re just like, oh, here&#39;s some people. They&#39;re just like, even when they they introduce was TC, is that his name? When they introduce him, he is clearly the hillbilly, but he is also never treated that way.</p>
                            <p>Bryan White (51:43.809)
                            Yeah, yeah.</p>
                            <p>Bryan White (51:49.813)
                            Yeah.</p>
                            <p>Bryan White (51:52.725)
                            Yeah, he&#39;s wearing wolf skins. True, yeah, like that&#39;s, that is true. There is like a sort of like objective attitude towards everybody. Like nobody is a stereotype. Even though they&#39;re sort of presented as such, nobody treats them as though they are. It&#39;s, that&#39;s an interesting, I&#39;ve never considered that. That&#39;s a really interesting observation.</p>
                            <p>Dave (52:12.118)
                            Like the movie takes no position on what those stereotypes mean or how we are supposed to feel about them.</p>
                            <p>Bryan White (52:18.101)
                            Yeah, yeah. Because actually, there&#39;s a couple of characters who, you know, they turn out to be bad guys. But there&#39;s a couple of them that I actually really like, like that kind of neurotic lady that Karen sort of. Yeah. But yeah, it&#39;s a whole it&#39;s a whole thing. But also everywhere she goes in this, somebody turns to her and goes, hey, aren&#39;t you famous or like I&#39;ve seen you on TV?</p>
                            <p>Dave (52:29.715)
                            Yeah, her friend.</p>
                            <p>Bryan White (52:44.969)
                            And I swear Slim Pickens is one of those people, because there&#39;s a scene a little bit. He says it, but does he do it in this one also? I could swear he does, unless.</p>
                            <p>Dave (52:47.959)
                            He says it later.</p>
                            <p>Dave (52:52.446)
                            No, because you don&#39;t, he&#39;s actually not introduced in this scene. You&#39;re thinking of the other guy who looks a little bit like him. The one who owns the cattle, like the cattle ranch. Slim Pickens is the sheriff. Yeah, this guy, I can&#39;t remember his name. He looks a little bit like Lou, what&#39;s his name? Lou Ayers or something like that. He looks a lot like him. You&#39;d recognize him from a shitload of other like 70s drive-in trash. But yeah, I, at first I was like, that&#39;s not him though, is it? But it is not.</p>
                            <p>Bryan White (52:58.025)
                            Oh shit.</p>
                            <p>Bryan White (53:01.661)
                            Oh my God, those are different characters?</p>
                            <p>Bryan White (53:09.578)
                            Oh my god, whoa.</p>
                            <p>Bryan White (53:16.694)
                            No kidding.</p>
                            <p>Bryan White (53:19.457)
                            Boy is my face red. Yeah. I thought he was the same character because he&#39;s one of the people who&#39;s like, hey, you&#39;re on TV and then she sees Slim Pickens later and he goes don&#39;t I know you from television? It&#39;s like didn&#39;t you Jesus Christ? Yeah, but like this is like a running joke that goes throughout the whole thing and it&#39;s like I don&#39;t if it&#39;s a joke. I don&#39;t get it. But you know, it just it just</p>
                            <p>Dave (53:31.286)
                            Yeah, someone else says it to her as well.</p>
                            <p>Dave (53:43.394)
                            Well, because he brings it up later at the very end. We&#39;ll come back to why it&#39;s possible, I think why they do that. But keep going for now. We&#39;re at the, it&#39;s the volleyball scene and Top Gun. But, but spoiler alert, there are werewolves.</p>
                            <p>Bryan White (53:53.293)
                            Uhhh...</p>
                            <p>Bryan White (53:58.006)
                            Yeah, playing with the boys.</p>
                            <p>Bryan White (54:02.357)
                            Yep, there are werewolves about. Oh, but right, yeah, but like before we do move on there, like this scene, we go back to Carradine, who all of a sudden has to be wrestled away from the fire&#39;s edge, throwing himself on it. And then like later on, we see him again, and he&#39;s having a really good time. So like, what the fuck is going on with this guy?</p>
                            <p>Dave (54:20.502)
                            Yeah, this is the part of the story that I think does not come across very well. Like what he&#39;s so upset about because he&#39;s muttering to himself about like the old ways and something, something, something.</p>
                            <p>Bryan White (54:24.397)
                            Yeah.</p>
                            <p>Bryan White (54:32.437)
                            Yeah, but it kind of sets you up for the sort of the payoff towards the end as to what the function of the colony really is, because as we find out, like, it&#39;s it&#39;s a very specific thing which may kind of makes it weird as to why they wanted to send her here because she&#39;s not a werewolf.</p>
                            <p>Dave (54:48.778)
                            Yeah, no, this part, that&#39;s the part, the convoluted part of the story does not, it kind of falls apart.</p>
                            <p>Bryan White (54:54.057)
                            Yeah. But yeah, we there&#39;s I think I think we get our first sort of like overnight scene, which is where we hear the wolves in the in the forest. That&#39;s like, all right. Now, you know, now we&#39;re getting somewhere. But not before we go back to the city, where we have a scene at the medical examiner&#39;s office where Terry and Chris are there to talk about Eddie Quist&#39;s body. And of course,</p>
                            <p>Bryan White (55:20.725)
                            the medical examiner is like eating, or he has food right next to the body parts. And I am proposing a moratorium on the eating coroner trope because I&#39;m so fucking.</p>
                            <p>Dave (55:29.742)
                            I it&#39;s nobody&#39;s gonna listen to you, especially not retroactively.</p>
                            <p>Bryan White (55:32.989)
                            I know. They keep doing it. Well, I can&#39;t go back in time, but from now on, if I see it again, somebody&#39;s going to jail.</p>
                            <p>Dave (55:40.342)
                            Yeah, the either the ghoulish morgue guy or the eating morgue guy.</p>
                            <p>Bryan White (55:46.361)
                            like I think like did you ever see just was it uh Autopsia Jane Doe? Like I think they were doing that as recently as that. It&#39;s like ah come on you gotta do something else.</p>
                            <p>Dave (55:50.531)
                            Yes.</p>
                            <p>Dave (55:55.654)
                            I think it&#39;s like it&#39;s that way in Autopsy of Jane Doe. I think it&#39;s just sort of like, look, it&#39;s just their job. Something something. It&#39;s like, yeah, it&#39;s their job. It&#39;s also a dead body. Like I if I worked in a morgue, I wouldn&#39;t be sitting there eating a plate of spaghetti. Just because it&#39;s funny. Because, you know, I&#39;ll be honest, it&#39;s not funny.</p>
                            <p>Bryan White (56:01.602)
                            Yeah.</p>
                            <p>Bryan White (56:10.561)
                            Right. Right.</p>
                            <p>Bryan White (56:15.765)
                            Nope. Nope. I don&#39;t I don&#39;t know if I ever thought it was cute, you know, but we see it. We sure do see it a lot. But the big the big reveal here in this scene is that Eddie&#39;s body is missing and it&#39;s not just missing. Yeah.</p>
                            <p>Dave (56:28.702)
                            Now I&#39;ve got questions about this. So they, you know, obviously they open the thing, the body&#39;s not there, shock and surprise. And then they look at the door and the door looks like it&#39;s been torn apart. It has claw marks in it. Now I am not a zoologist. I think that is well established. In order to make a slashing claw mark, one would need to slash, presumably.</p>
                            <p>Dave (56:56.794)
                            I don&#39;t know how you can do that when you&#39;re confined in a tiny box.</p>
                            <p>Bryan White (57:01.429)
                            Like they&#39;re little slash marks, you know?</p>
                            <p>Dave (57:03.678)
                            And also, don&#39;t you think someone would have noticed him get out? And also, second also, don&#39;t you have to pull the handle for it to open? Look, I&#39;m getting CinemaSins up the yin yang here, and I know that, but, but it bothered me, because this movie is otherwise very well put together.</p>
                            <p>Bryan White (57:12.373)
                            Yeah, yeah.</p>
                            <p>Bryan White (57:15.793)
                            I know you&#39;re getting you&#39;re really you&#39;re really in the weeds with this but uh yeah the the</p>
                            <p>Bryan White (57:23.521)
                            Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right. Right</p>
                            <p>Bryan White (57:40.525)
                            Jesus. Oh, wait, no, no, no. There&#39;s a tennis court scene, which is where he&#39;s yes. So it&#39;s just again, it&#39;s like just social stuff. And this is where he slim pickings is is introduced. And I don&#39;t know if you noticed this he is bursting out of that uniform. It&#39;s like they maybe they they like, like maybe they sized him and like put him in wardrobe and then like, he didn&#39;t come back for</p>
                            <p>Dave (57:43.83)
                            That&#39;s Slim Pickens.</p>
                            <p>Dave (57:56.727)
                            Yeah.</p>
                            <p>Dave (58:03.182)
                            I don&#39;t know if they&#39;re trying to make him look smaller, cause he&#39;s a very large man.</p>
                            <p>Bryan White (58:06.953)
                            Yeah, but like this heat like that that uniform is literally bursting at the buttons like it&#39;s crazy. Yeah, but now we go back to the we go back to the colony. It&#39;s another scene at night. And Karen and whatever that lady&#39;s name is are out walking around. There&#39;s more howling. There&#39;s you know</p>
                            <p>Dave (58:14.09)
                            Yeah, it&#39;s like Slim Pickens, you are just poured into that uniform.</p>
                            <p>Bryan White (58:34.725)
                            suggestions of wolves in the forest. And of course, the flashlight goes out.</p>
                            <p>Bryan White (58:42.297)
                            because it&#39;s a horror movie and it&#39;s a flashlight in a horror movie.</p>
                            <p>Dave (58:45.154)
                            And then you got to smack it because it&#39;s a flashlight in a horror movie.</p>
                            <p>Bryan White (58:47.273)
                            And you gotta hit it a couple of times, because that&#39;s how you do it. I mean, they even work like that today. Right. You just hit them until they work, even if the battery&#39;s dead. Yep. Yep. All right. But we find some we find some dead cows. When the light comes back on, it happens to be illuminating a cow, which has definitely been like fucked up by a wolf.</p>
                            <p>Dave (58:49.966)
                            Because that&#39;s how batteries work.</p>
                            <p>Dave (58:53.686)
                            Like children.</p>
                            <p>Dave (58:59.271)
                            Eww!</p>
                            <p>Bryan White (59:18.117)
                            And it&#39;s just, I guess, like more suggestions that there&#39;s werewolves, like they&#39;re really kind of, they&#39;re really, this is a slow progression towards the actual werewolves. Like it&#39;s really kind of taking its time. If I can say one thing about.</p>
                            <p>Dave (59:30.606)
                            Well, this again is like he didn&#39;t read this book because this movie is the suggestion of a story filled in with Nancy Drew plot points.</p>
                            <p>Bryan White (59:36.001)
                            Yeah.</p>
                            <p>Bryan White (59:39.605)
                            Yeah, because like if like even though like I prefer this movie, American Werewolf kind of gets to the point like almost immediately. And then and then like it kind of lets you sit on it for a little while. But. This one really does take its time. So it&#39;s like more like dead animals. Oh, boy, coyotes didn&#39;t do that. Like, you know, it&#39;s it&#39;s obviously something bigger. You&#39;re at a movie called The Howling, what could possibly have done it? Like, let&#39;s let&#39;s kind of move things along, I guess, I suppose.</p>
                            <p>Dave (01:00:08.694)
                            Yeah, you&#39;re also all werewolves. You know what did it.</p>
                            <p>Bryan White (01:00:12.341)
                            I don&#39;t know. I don&#39;t know. I don&#39;t know. I don&#39;t know. I don&#39;t know. I don&#39;t know. I don&#39;t know. I don&#39;t know. I don&#39;t know. I don&#39;t know. I don&#39;t know. I don&#39;t know. I don&#39;t know. I don&#39;t know. I don&#39;t know. I don&#39;t know. I don&#39;t know. I don&#39;t know. I don&#39;t know. I don</p>
                            <p>Dave (01:00:14.398)
                            I guess, but you don&#39;t have to be so mysterious.</p>
                            <p>Dave (01:00:36.346)
                            Absolutely not. He&#39;s only dressed in weird animal skins and is sniffing the air.</p>
                            <p>Bryan White (01:00:40.137)
                            Yeah, he&#39;s got like. Yeah, he&#39;s got like a wolf skin on his back. I&#39;m surprised they didn&#39;t give him like a unibrow to really like drive it home. They get pretty close, but like, yeah, there&#39;s something about him, like something about his face, like I don&#39;t think he&#39;s wearing makeup or anything like that, but there&#39;s.</p>
                            <p>Dave (01:00:48.162)
                            They came pretty damn close.</p>
                            <p>Dave (01:00:54.494)
                            Now, that guy is not a good actor, but he is selling this character.</p>
                            <p>Bryan White (01:00:58.273)
                            He&#39;s he was definitely the best pick for the character. Yep, we go to a group therapy scene where they&#39;re trying to help Karen kind of work through her trauma. She&#39;s the the problem is that she&#39;s experiencing like basically amnesia, like whatever she saw in that booth back in the city. Her mind is just blocking. And so she&#39;s.</p>
                            <p>Dave (01:01:20.694)
                            And so in this scene, are they like doing like a, what is it, hypnosis or something? Cause it&#39;s like, she&#39;s just narrating what happened in like, in the sort of like theater in the round scenario and he&#39;s talking to her as though she is hypnotized, but she is very clearly just sort of lucid and awake.</p>
                            <p>Bryan White (01:01:36.555)
                            Yeah, like.</p>
                            <p>Bryan White (01:01:42.677)
                            Yeah, I don&#39;t know. I don&#39;t think so. I think they might have like if they if they were doing that, they might have just they might have been a little bit more deliberate on that because this really does just kind of like look like group therapy like</p>
                            <p>Dave (01:01:51.542)
                            Look, this is what group therapy is like, right?</p>
                            <p>Bryan White (01:01:54.761)
                            Yeah, yeah, but like she gets close. She&#39;s getting closer and closer to kind of figuring out what happened. And and then we cut to one of my favorite scenes in the whole movie. Walter Paisley&#39;s occult bookshop.</p>
                            <p>Dave (01:02:08.738)
                            with customer numero uno.</p>
                            <p>Bryan White (01:02:11.761)
                            Yeah, those those weird ass nuns. I love the nuns.</p>
                            <p>Dave (01:02:15.534)
                            I love the nuns. The nuns are such a great touch because one of them gives the other kind of like a sly smirk. So I don&#39;t know. So I have the 4K that Scream Factory put out like earlier this year or whatever. And you can see it all really well. And she kind of like, it&#39;s this really cute moment between them where like, they&#39;re just kind of do a nonverbal exchange and they&#39;re like, eh, fuck it, let&#39;s do it.</p>
                            <p>Bryan White (01:02:21.206)
                            Yeah.</p>
                            <p>Bryan White (01:02:37.045)
                            Yeah, and one of them is definitely like a dude, right? Like he looks like a guy in a nun&#39;s costume.</p>
                            <p>Dave (01:02:43.731)
                            I don&#39;t know. They both look like they both have the habit on and there&#39;s.</p>
                            <p>Bryan White (01:02:44.718)
                            Huh.</p>
                            <p>Bryan White (01:02:47.337)
                            Yeah, but like, but they they walk in and there&#39;s just this like demon monster head on the table and that&#39;s where they do the whole like, look at this type of thing. But, uh, yep, yep. Forst Ackerman makes cameo here and like it is not even a little subtle like like the corpsman one, the corpsman one like if you don&#39;t know what the deal is with that guy like he&#39;s just an extra in the scene. In this one.</p>
                            <p>Dave (01:02:57.906)
                            And you also get Uncle Forie.</p>
                            <p>Dave (01:03:06.061)
                            No.</p>
                            <p>Bryan White (01:03:14.265)
                            And then, you know, Ackerman turns around and is holding copies of famous monsters of film land. Hey. Yeah, yeah, it looks at the camera. By the way, I&#39;m the guy who makes these magazines. Yep. But like, this is the scene and this is, this is Dick Miller, just pitch perfectly, A plus performance, because. Yeah. He&#39;s.</p>
                            <p>Dave (01:03:17.718)
                            Yeah, I mean, he may as well have been going, Ahem, ahem, ahem, you know me? Ahem.</p>
                            <p>Dave (01:03:33.53)
                            I have never seen Dick Miller give a bad performance in anything. This guy fucking rules.</p>
                            <p>Bryan White (01:03:38.369)
                            He&#39;s so great. Cause like, he&#39;s like, we got everything you could want. We all books about body snatchers. We got books about body snatchers, Satanists. The Manson family used to shoplift here. Bunch of dead beats.</p>
                            <p>Dave (01:03:50.12)
                            And she&#39;s holding the book and she says something and he&#39;s like, yeah, it&#39;s a real good book</p>
                            <p>Bryan White (01:03:55.533)
                            But they show you the pages of it. It&#39;s like a lot like old timey, like woodcuts and stuff like that. It&#39;s like a really cool, cool art in that book.</p>
                            <p>Bryan White (01:04:04.589)
                            when they take you around and he he takes them to the to the counter they&#39;re about to check out and this is where we&#39;re introduced to check off silver bullets. Because there&#39;s a box of silver bullets that some guy ordered and never picked up.</p>
                            <p>Dave (01:04:14.53)
                            Oh, yeah, this is this movie goes hard on Chekhov&#39;s gun because earlier she says to her friend, the unnamed friend, she&#39;s like, doesn&#39;t your husband, whatever, doesn&#39;t he have a rifle? And it&#39;s like, aha. Yeah.</p>
                            <p>Bryan White (01:04:27.077)
                            Oh yeah. Yep. Yep. But uh there&#39;s also like an info dump here which really kind of keys you into the movie&#39;s particular take on werewolf lore because like you see like most most people who aren&#39;t really kind of you know really into werewolf movies like they&#39;re gonna they they know one thing werewolf change on full moon right this movie throws that out and he says and he Walter Paisley tells him the shapeshifters</p>
                            <p>Bryan White (01:04:56.921)
                            There&#39;s no cycle of the moon kind of thing going on here. They can just turn into werewolves whenever they want.</p>
                            <p>Bryan White (01:05:04.429)
                            Um we go back to the forest and um Tom Atkins successfully shoots a rabbit and uh he he doesn&#39;t really know what to do with it. TC tells him to take it to Marsha who&#39;s his sister. That&#39;s uh his name is Elizabeth Brooks.</p>
                            <p>Dave (01:05:23.39)
                            I wrote this down because she is also not a great actor.</p>
                            <p>Bryan White (01:05:27.519)
                            She gets a weird credit in the credits too. It says introducing Elizabeth Brooks.</p>
                            <p>Dave (01:05:31.574)
                            Yeah, Elizabeth Brooks. And she apparently was like a kind of a hardcore Christian. And she said, and I don&#39;t know if this was like a born again situation because what you&#39;re leading up to is a horrible sex scene. And she claimed that they told her that it would be the full frontal nudity would be obscured by the fire or whatever, and it kind of is.</p>
                            <p>Bryan White (01:05:37.301)
                            Huh. I had no idea.</p>
                            <p>Bryan White (01:05:44.759)
                            Yeah.</p>
                            <p>Bryan White (01:05:54.493)
                            Mmm, it kind of is, maybe.</p>
                            <p>Dave (01:05:55.77)
                            And I don&#39;t know if that&#39;s her sort of like doing the Linda Lovelace thing where she&#39;s like, No, they forced me to do it. It&#39;s like, did they though? I want to blame the victim here, but.</p>
                            <p>Bryan White (01:06:02.777)
                            Yeah, I mean, right. Like, yeah, like, because because I&#39;ve, I&#39;ve, I&#39;ve also heard the part about the towards the end, like the big sort of third act scene, where one of D Wallace&#39;s like actual contract agreements was they would not add nudity to the movie that wasn&#39;t already written into the script. So this scene was already written into the script. She had to have known</p>
                            <p>Dave (01:06:29.134)
                            Which is weird because I didn&#39;t think they still did shit like that by 1980.</p>
                            <p>Bryan White (01:06:33.077)
                            Yeah. Yeah. I don&#39;t know.</p>
                            <p>Dave (01:06:35.854)
                            Cause that&#39;s some real, that&#39;s like, that&#39;s a real Italian move.</p>
                            <p>Bryan White (01:06:39.029)
                            that really that really is it&#39;s a little scuzzy. But this is like it&#39;s this just sort of like sets up the kind of the downfall of Tom Atkins. Because he gives the gives her the rabbit and she starts to kind of like cut it up and prepare it to be cooked. But really, the whole thing was just to sort of get them together so she can put the moves on them.</p>
                            <p>Dave (01:06:58.058)
                            And I&#39;ll tell you what, like this movie, but a part of the reason it&#39;s so good and so fun is that it has a huge cast and they&#39;re all really good and they all know what they&#39;re doing. Like everyone knows why they&#39;re there and what their character is. She&#39;s like the only one to me that feels like she doesn&#39;t. Like she&#39;s constantly like just fucking chomping every bit of scenery she can find. She&#39;s making huge eyes. Like her eyebrows are doing a lot of heavy lifting.</p>
                            <p>Bryan White (01:07:06.83)
                            Mm-hmm.</p>
                            <p>Bryan White (01:07:21.03)
                            Yeah.</p>
                            <p>Dave (01:07:28.406)
                            Like this is, this is a lot and she just comes on so strong.</p>
                            <p>Bryan White (01:07:30.569)
                            It makes me. She&#39;s definitely the movie&#39;s sex appeal, but I&#39;m wondering if. And I mean, I know why she&#39;s in it like she&#39;s obviously just easy skin, but like I wonder if they couldn&#39;t have just done the same thing because the whole angle like her sort of political position in the movies kind of ultimate philosophy. It makes me wonder if they couldn&#39;t have just done that with the TC character because like he&#39;s doing the same thing. He&#39;s just.</p>
                            <p>Dave (01:07:59.182)
                            I think they needed a woman though. They needed the bitch. And I think that&#39;s kind of what she is, but it&#39;s like, she&#39;s not even really that. Because they can&#39;t decide like, is she menacing or is she just an asshole? And she, in the end, she happens to be both, but she doesn&#39;t really give you either that well.</p>
                            <p>Bryan White (01:08:02.818)
                            guess.</p>
                            <p>Bryan White (01:08:09.525)
                            No, she&#39;s just...</p>
                            <p>Bryan White (01:08:12.845)
                            Yeah.</p>
                            <p>Bryan White (01:08:17.569)
                            No, but yeah, like she&#39;s really kind of like the like the sex pot in the in the movie because like the cast does not have a whole lot of that it&#39;s it&#39;s like a lot of movies at the time it&#39;s a very. I know I can&#39;t take my eyes off of. But yeah, this is the whole thing they&#39;re just setting up a little tension to pull them away from from Karen.</p>
                            <p>Dave (01:08:28.046)
                            We just talked about slim pickings. Just fucking poured into those pants.</p>
                            <p>Dave (01:08:42.486)
                            Well, she&#39;s also a contrast between the other women in the movie in that they are all very like women of the 80s. They&#39;re working women. They&#39;re independent. They&#39;re, you know, kind of empowered. You know, they&#39;re where they&#39;re not quite be Arthur, but they&#39;re wearing like casual business attire. She is a like a something to be objectified. Yeah, basically.</p>
                            <p>Bryan White (01:08:47.153)
                            Right.</p>
                            <p>Bryan White (01:09:00.022)
                            Yeah.</p>
                            <p>Bryan White (01:09:02.553)
                            she&#39;s like a she&#39;s like a Led Zeppelin groupie. Yeah. But uh yeah so like the the scene just sort of sets up the dynamic between the two because then we cut to another night scene where Tom Atkins is walking around outside and um he gets attacked by a wolf. He&#39;s taken down and he&#39;s bitten but he&#39;s not ripped apart. He&#39;s just bitten. It&#39;s important detail. But</p>
                            <p>Bryan White (01:09:32.087)
                            the other day. Uh he goes back deal with it. Um</p>
                            <p>Bryan White (01:09:37.145)
                            Karen&#39;s friend, Terry, comes up from the city to visit her.</p>
                            <p>Dave (01:09:43.218)
                            Oh man, Terry. Terry, you should have stayed where you belong.</p>
                            <p>Bryan White (01:09:44.053)
                            And this is like, yeah, this is oh, I know, poor Terry. But this is this is the scene that kind of like establishes the turn because the whole time Tom Atkins is saying, but telling people like I&#39;m a vegetarian. I know I try I try not to eat meat, but for whatever reason, she&#39;s brought like food with her, but she only brought meat. And now he&#39;s like, no, it&#39;s fine. I&#39;ll eat anything if I&#39;m hungry enough. And he&#39;s over there just like chowing the fuck down on like ribs or something.</p>
                            <p>Dave (01:10:09.986)
                            Yeah, he is like, fucking what is, what&#39;s, got changed for 100? What is Chris Rock&#39;s character? Like he is basically like licking his fingers, eating it off the bone, like, yeah.</p>
                            <p>Bryan White (01:10:17.37)
                            How much for one rib? Chris, I&#39;m gonna get you sucka.</p>
                            <p>Bryan White (01:10:26.161)
                            Oh, it with gusto. Yeah. But, so yeah, after this scene, we cut again to the cab and there&#39;s a lot of cuts in this movie. It jumps around a lot. You cut to the bedroom and it&#39;s Bill, Tom Atkins and Karen in bed and he&#39;s reading You Can&#39;t Go Home Again by Thomas Wolfe. Yep, and they do this a bunch. They&#39;re going to do this a couple more times and I noticed all of them.</p>
                            <p>Dave (01:10:48.371)
                            That&#39;s another thing. It&#39;s a cute thing. The wolf with the wolf part.</p>
                            <p>Bryan White (01:10:55.597)
                            But Karen, they go to sleep, Karen wakes up in the middle of the night and Bill is gone. And he&#39;s out in the forest to meet up with Marcia. That&#39;s Elizabeth Brooks&#39; character&#39;s name is Marcia. And he&#39;s gonna meet up for some, it&#39;s gross. It&#39;s for, he meets up with her for some wolfy forest sex. And I don&#39;t, you know, I&#39;m gonna say this. I don&#39;t think it&#39;s possible for anybody to shoot. Well, not possible, because I&#39;ve seen the hunger and it&#39;s awesome, but.</p>
                            <p>Dave (01:11:10.273)
                            Man, this scene fucking sucks.</p>
                            <p>Bryan White (01:11:25.461)
                            I don&#39;t think it&#39;s possible for anybody to just shoot a sex scene that isn&#39;t awkward as fuck.</p>
                            <p>Dave (01:11:32.522)
                            I mean, granted, what you&#39;re doing on camera is already incredibly awkward. But this is like it&#39;s bad. It is just like it is a bad sex scene. It is a dumb sex scene. It looks terrible.</p>
                            <p>Bryan White (01:11:33.429)
                            And maybe it&#39;s just because I watched.</p>
                            <p>Bryan White (01:11:37.919)
                            Yeah.</p>
                            <p>Bryan White (01:11:41.313)
                            Maybe.</p>
                            <p>Bryan White (01:11:43.99)
                            Yeah.</p>
                            <p>Bryan White (01:11:46.441)
                            Yeah, and it&#39;s made all the it&#39;s made all the worse by like the addition of special effects and then kind of a wonky animated scene. I personally I love the transformation makeup like where they&#39;re all furry and shit like I love that. But the</p>
                            <p>Dave (01:11:52.07)
                            And you&#39;re being very generous with that term.</p>
                            <p>Dave (01:12:02.506)
                            I think his looks good, hers I think looks kinda weird. Cause she has this blue cast on her face.</p>
                            <p>Bryan White (01:12:05.813)
                            Yeah, but yeah, like.</p>
                            <p>Bryan White (01:12:09.461)
                            Yep. Yeah. Oh, right. Because there is a lot of like they do a lot of like color theory type shit in this scene because like there&#39;s the blue of the night and the sort of orange of the glow of the fire and stuff like that. I don&#39;t know. Maybe it&#39;s just because I watched Nightbeast right before I did this one again. And that one&#39;s also got a fucking awful sexy in it. So like, I&#39;m just like, I&#39;ve been assaulted today. But they this they transform and it&#39;s like</p>
                            <p>Dave (01:12:14.732)
                            Yeah.</p>
                            <p>Bryan White (01:12:33.877)
                            It&#39;s a long sort of back and forth because like they start to growl and then they&#39;ve got the mouth appliances in which look huge and really uncomfortable to wear.</p>
                            <p>Dave (01:12:42.614)
                            They do and they look kind of bad when like they linger too much and it&#39;s like drooling and you can&#39;t like it&#39;s sort of like are they drooling intentionally or is that because they can&#39;t really open their mouths?</p>
                            <p>Bryan White (01:12:48.597)
                            He&#39;s drooling.</p>
                            <p>Bryan White (01:12:56.249)
                            I would I would imagine that it&#39;s probably intentional just to make it a little little grody, you know, but there is one thing about the transformation scenes in this movie that I love and that I&#39;ve the only other place I&#39;ve ever seen them do it is in Salem&#39;s Lot and it&#39;s that reflective eye thing that they do. I don&#39;t know why I don&#39;t like I don&#39;t know I don&#39;t know where it came from but I think it&#39;s cool as hell like</p>
                            <p>Dave (01:13:17.198)
                            Mm-hmm.</p>
                            <p>Dave (01:13:25.33)
                            I think it came from Salem&#39;s Lot. I think because he just when Toby Hooper decided he wanted that character to be more of an animal, he was like, how how do I make someone look like? How do I sell animal when it&#39;s a blue guy and someone was like, well, animals have reflective eyes. Boom. But also wolves do, too.</p>
                            <p>Bryan White (01:13:29.29)
                            It must have, like it.</p>
                            <p>Bryan White (01:13:35.746)
                            Yeah.</p>
                            <p>Bryan White (01:13:41.174)
                            Yeah.</p>
                            <p>Bryan White (01:13:44.633)
                            That&#39;s yeah, that just because they do it. They don&#39;t do it all the time. They do it a few times in the movie. And when they do, it&#39;s awesome. Most notably at the end during Karen&#39;s transformation, like they really, really make it a point to sort of draw your attention to it. But I.</p>
                            <p>Dave (01:14:00.278)
                            because otherwise they have those big dumb green eyes.</p>
                            <p>Bryan White (01:14:05.131)
                            the contact lenses are not great.</p>
                            <p>Dave (01:14:09.07)
                            because I think they ran out of money towards the end of this movie.</p>
                            <p>Bryan White (01:14:11.861)
                            They did. They actually they went way over budget on this. I think this movie was originally like a six figure movie that bled into the into the million range. And I think it&#39;s</p>
                            <p>Dave (01:14:21.258)
                            Roger Corman saw that and was like, I could make it three movies for that much money. And then he did.</p>
                            <p>Bryan White (01:14:24.021)
                            Yeah, yeah, and then he did. But yeah, I also I think it&#39;s why the Karen Wolf looks so fucking weird at the end is because like they just lacked money.</p>
                            <p>Dave (01:14:33.069)
                            Yes.</p>
                            <p>Bryan White (01:14:38.369)
                            But moving on, this is the terrible sex but I&#39;ll be I&#39;ll be playing I&#39;ll be playing harmonica. Yep. But we we go to another scene where</p>
                            <p>Dave (01:14:41.678)
                            Terrible Wolf Sex. Which is also the name of my new band.</p>
                            <p>Dave (01:14:56.682)
                            Also, wait, did you notice that in her cabin or where he brings the rabbit to her, she has just the fucking worst, like, flea market painting of a wolf on the wall? Like, it looks like the kind of shit that you paint on the side of a van.</p>
                            <p>Bryan White (01:15:07.73)
                            Oh yeah, yeah.</p>
                            <p>Bryan White (01:15:11.965)
                            Oh, sure. Yep. I mean, who knows? They probably did. Like they probably went to some night market and were like, OK, three wolf moon. Grab that one. And then that one from Grizzly Adams. Get that one.</p>
                            <p>Dave (01:15:22.998)
                            It&#39;s the kind of thing where I would like, I would give it a second look and then I&#39;d have to be like, no, you&#39;re in your 40s. You do not put that shit in your house.</p>
                            <p>Bryan White (01:15:32.841)
                            Yeah, yeah. Do you still have that black velvet bullfighter painting?</p>
                            <p>Dave (01:15:38.134)
                            No, that belonged to my old roommate from a thousand years ago, Anne.</p>
                            <p>Bryan White (01:15:43.566)
                            Oh, no, that was a hell of a painting.</p>
                            <p>Dave (01:15:47.1)
                            I think, maybe Emily got me, I don&#39;t know. I do not have it anymore.</p>
                            <p>Bryan White (01:15:49.269)
                            Uh, gosh, that&#39;s a bummer. Uh, but moving on, Terry is doing more of her Nancy Drew stuff, and this is when she kind of she&#39;s she&#39;s shuffling through like all the drawings that she has, and she&#39;s down on the beach and she realizes that the beach she&#39;s on is the landscape that she found drawn in Eddie Quist&#39;s apartment with like the rest of his art.</p>
                            <p>Dave (01:16:09.55)
                            And so this is the beach that looks like the Goonies to me.</p>
                            <p>Bryan White (01:16:13.625)
                            Right. It certainly could be. I&#39;d have to look it up. Like, I&#39;m not really sure where the Goonies was was shot, but I mean, it was probably somewhere in Northern California because it kind of has that look of like once you go up above like Bakersfield, it starts to have that. Everything gets really forested and like the cut like. Look at. Yeah, like the town in. Shit, what&#39;s it called in the fog?</p>
                            <p>Dave (01:16:29.218)
                            It&#39;s very, it&#39;s very Pacific Northwest looking.</p>
                            <p>Dave (01:16:38.09)
                            That is Monterey Bay. No, that&#39;s... Oh my god.</p>
                            <p>Bryan White (01:16:39.81)
                            Yeah.</p>
                            <p>Bryan White (01:16:43.409)
                            You know what I&#39;m talking about, that place. Yes, yeah. That&#39;s right. So it&#39;s it&#39;s all like it&#39;s all like up up there. Everything&#39;s a little foggy. It&#39;s definitely like you said, Pacific Northwest, because I think I think the Goonies is supposed to take place in Washington.</p>
                            <p>Dave (01:16:45.762)
                            where the birds are shot. Bodega Bay.</p>
                            <p>Bryan White (01:17:03.841)
                            But yeah, this is the whole vibe. It&#39;s maritime, coastal, forested coastal area. And she is going to go back to kind of tell Karen what she&#39;s found on her way through the woods. She&#39;s drawn to Marsha&#39;s cabin by a mysterious voice. And so when she goes in, there&#39;s nobody there, but she finds.</p>
                            <p>Bryan White (01:17:32.313)
                            Piece of Art by Eddie Quist and the Smiley Face sticker.</p>
                            <p>Dave (01:17:37.009)
                            I think that&#39;s the one. This is the piece of art where it&#39;s like this is clearly a PA that did this.</p>
                            <p>Bryan White (01:17:41.589)
                            Well, I think it was a drawing of, because there&#39;s also in the scene where Bill is in Marcia&#39;s cabin, there&#39;s a drawing of her that also has that kind of scratchy scribbly style, that Eddie Quist style, that&#39;s probably supposed to make you go, hmm. But this is the scene that really kind of nails it down because she goes through the door with the smiley face sticker. We find Eddie&#39;s room with more art and more clippings. And this is the one that you&#39;d mentioned earlier.</p>
                            <p>Bryan White (01:18:10.261)
                            It&#39;s a clipping that says a gang rape for the sailor&#39;s wife. Yep, there&#39;s another one that says the rape murderers forget to wash off their victims blood. There&#39;s one that says sudden death for the tennis queens. And then my favorite, it&#39;s the last one. The heels of the corpse had turned purple. But yeah, so she she realizes like she&#39;s in the lion&#39;s den here like.</p>
                            <p>Dave (01:18:13.526)
                            Yes.</p>
                            <p>Dave (01:18:23.662)
                            Okay, well now you got me interested.</p>
                            <p>Dave (01:18:31.158)
                            I saw that one as well.</p>
                            <p>Dave (01:18:38.254)
                            This is back when journalism was fun.</p>
                            <p>Bryan White (01:18:40.433)
                            Oh my God, like they must have made these because they&#39;re fucking hilarious. But also like, I wonder like if true crime magazines of the era actually had titles like this or like that magazine violent world that the Misfits wrote a song about.</p>
                            <p>Bryan White (01:18:57.217)
                            But yeah, this is this is where she is. She&#39;s finally attacked. She&#39;s attacked by a wolf. This is the first glimpse of the wolf suit that we get in the movie. Like, it&#39;s really the first time that we really see the wolves proper because even in the scene where Bill and Marsha are transforming for wolf sex, it&#39;s still it&#39;s not the full transformation. And it&#39;s still it&#39;s like it&#39;s not, you know, it&#39;s really they&#39;re not giving you. Yeah. But she gets like chased out of the place and she&#39;s in a little like</p>
                            <p>Dave (01:19:19.038)
                            It&#39;s all tight. It&#39;s like tight close up shots.</p>
                            <p>Bryan White (01:19:27.201)
                            little shack off to the side. She finds she gets chased out. She finds like a hatchet. She hides in the woodshed and the wolf like rips through the wall and is attacking her is reaching in with his wolf hand. And she chops off the wolf&#39;s hand with the hatchet and the wolf obviously runs away. But this is like one of my favorite parts of the whole movie where she&#39;s like sitting there watching it and we see it transform back into like a human hand. So awesome.</p>
                            <p>Bryan White (01:19:55.157)
                            And it&#39;s such a gross little effect too. But yeah, this is also this was the high era of the bladder effect. I&#39;m not sure. I&#39;m not really sure who introduced this, but like.</p>
                            <p>Dave (01:20:03.838)
                            Yeah. I believe it&#39;s Tom Savini.</p>
                            <p>Bryan White (01:20:10.337)
                            must have been, because all of a sudden everybody was doing it. And we talked about this in our Amityville 2 episode, because there&#39;s a shitload of bladder effects in the Amityville 2 episode, like a lot of them.</p>
                            <p>Dave (01:20:20.702)
                            It must be cheap or easy.</p>
                            <p>Bryan White (01:20:22.633)
                            It&#39;s got to be because it&#39;s really it&#39;s just little like a little hand pump and like the bladders are usually condoms. And it&#39;s just latex appliances over it and little hoses that you hide in you know, the person&#39;s hair or whatever. And you just give a little pump here and there and it&#39;s just gross enough. It works.</p>
                            <p>Dave (01:20:41.078)
                            Like it doesn&#39;t actually convey anything other than something supernatural is happening. Like it&#39;s not a specific thing that it&#39;s doing. Because there&#39;s no reason why it would be happening in this case. It&#39;s just like, oh, he&#39;s not human.</p>
                            <p>Bryan White (01:20:46.529)
                            Yeah.</p>
                            <p>Bryan White (01:20:49.538)
                            Sure.</p>
                            <p>Bryan White (01:20:54.169)
                            No, it&#39;s cheap. It&#39;s cheap. It&#39;s easy. It&#39;s gross. And it&#39;s just yeah, it just it suggests it&#39;s very, very effective in suggesting something&#39;s up. But this is where she she basically goes back to the doctor&#39;s office and gets on the phone, she calls up Chris and</p>
                            <p>Dave (01:21:13.282)
                            Wait, did you notice that the doctor for some reason has a signed photograph of Lon Chaney Jr. on the wall? Just for no reason.</p>
                            <p>Bryan White (01:21:20.482)
                            Yep, yep, and he&#39;s also just because it&#39;s a werewolf movie and he&#39;s the wolf man.</p>
                            <p>Dave (01:21:26.794)
                            I mean, if someone gave me a signed picture of Launching at Junior, I&#39;d put it on my wall too, I guess.</p>
                            <p>Bryan White (01:21:30.641)
                            Oh yeah, yeah, but he&#39;s also it&#39;s a great picture. Alon Cheney Jr. like this is definitely before he was a booze hound. But he also has a copy of Allen Ginsburg&#39;s Howl on the desk. Oh, yeah, they&#39;re going to do it one more time. But she&#39;s talking to Chris and Chris is like, you go into his file cabinet and look up the name Quist and what she what she finds there is that T.C. and Marsha are both. Last name Quist.</p>
                            <p>Dave (01:21:35.957)
                            Oh yeah.</p>
                            <p>Dave (01:21:40.126)
                            Yeah, I noticed that too.</p>
                            <p>Bryan White (01:21:58.113)
                            and that they&#39;re probably, I don&#39;t really establish it, but I think they&#39;re just brother and sister of Eddie Quist because Eddie&#39;s also got a file in there. But before she can really do anything about it, we get to finally see the full wolf suit proper. It&#39;s awesome.</p>
                            <p>Dave (01:22:12.978)
                            And man, I&#39;ll tell you, this is when humor goes out the window.</p>
                            <p>Bryan White (01:22:16.349)
                            It&#39;s a brutal scene.</p>
                            <p>Dave (01:22:18.254)
                            Because it is slow. This is a slow death.</p>
                            <p>Bryan White (01:22:21.089)
                            Yeah, because he comes in smashing through the door and it&#39;s it&#39;s whoever&#39;s in that wolf suit is enormous because it&#39;s big. And we&#39;ve seen like</p>
                            <p>Dave (01:22:30.122)
                            Is it the guy, the guy who did Predator? That guy, the alien?</p>
                            <p>Bryan White (01:22:35.689)
                            Oh, I don&#39;t know. I don&#39;t know. It might have been because he&#39;s tall. He&#39;s very tall. But yeah, and he he he gives poor Terry a good once over like really really rips her up and he chops into her neck.</p>
                            <p>Dave (01:22:52.13)
                            But he just like holds her there staring at her and she&#39;s kind of like screaming and staring at him and the whole thing is kind of frozen and then he very slowly bites her.</p>
                            <p>Bryan White (01:23:00.929)
                            very slowly bites. Yeah, that&#39;s the thing. It&#39;s not like it&#39;s a savage tear or a big ol&#39; chop. Like it&#39;s... it&#39;s a loving embrace.</p>
                            <p>Dave (01:23:09.174)
                            Yeah, it&#39;s kind of unnerving.</p>
                            <p>Bryan White (01:23:11.177)
                            Yep. But she is killed by presumably Wolf Eddie is is who it is. We&#39;ll find out in a in a moment because Karen comes into the scene into the office and she finds Terry&#39;s body like all fucked up by the wolf on.</p>
                            <p>Dave (01:23:26.63)
                            And she is very casual about this.</p>
                            <p>Bryan White (01:23:29.845)
                            Yeah, she just kind of calmly puts the sheet over her body and then she steps away and then she turns and she backs up into the same gurney that was holding it. But now it&#39;s Eddie Quist who like leaps.</p>
                            <p>Dave (01:23:42.718)
                            Yeah, I&#39;m not sure I&#39;m on board for this, this little switcheroo. This is some slight, like real slight of hand stuff. Yeah.</p>
                            <p>Bryan White (01:23:46.785)
                            because he&#39;s fast. This is this is like Jason Voorhees shit that he&#39;s pulling like really because like all of us like she&#39;s gone. And he jumps up and there&#39;s like a big menacing back and forth where he we see him. This is really the first time we see him because like we only really catch you like glimpses of him in the past. You know, this is Robert Picardo who&#39;s playing the character.</p>
                            <p>Bryan White (01:24:12.765)
                            And he just for no other reason that it&#39;s awesome. He still got the bullets in his head that the cops shot him with. And so he like puts his finger into the hole. He says, I want to give you a piece of my mind. And it like pulls the bullet out. And then he proceeds to do like this is the movie&#39;s like flagship transformation, because before, like, you don&#39;t really see anybody transform or it&#39;s done kind of like in the way that they did it in like the Universal Wolf Man, where it&#39;s kind of like a.</p>
                            <p>Bryan White (01:24:41.057)
                            bit of a fade or cutaway and a cut back and there&#39;s a little bit more fur. Well, like this is the one where like his body changes shape. And I think it&#39;s great. I think it&#39;s better than Rick Baker&#39;s transformation. I do, I think Rick Baker&#39;s, because what it is is this one&#39;s a little bit more fast. It&#39;s a little bit more like smash cut. The American werewolf one really savers</p>
                            <p>Dave (01:24:54.42)
                            Really?</p>
                            <p>Dave (01:25:09.686)
                            Because I think American Werewolf is trying to make it look like an actual transformation would look this one. They&#39;re just like, here&#39;s goofy sci-fi movie. Like, it looks cool, but they&#39;re not so concerned with like, well, how do his limbs stretch like that shit?</p>
                            <p>Bryan White (01:25:15.255)
                            Yeah.</p>
                            <p>Bryan White (01:25:18.326)
                            Yeah, yeah.</p>
                            <p>Bryan White (01:25:24.149)
                            Yeah, yeah, because like, I mean, that stuff, like you see it, but also like, like I was saying, the American werewolf one is just a little slower. It&#39;s definitely in love with itself. Like they&#39;re really, they&#39;re really into it. And like, I think in the commentary for this movie, like Dante says, like, this was one of the last things that we shot and we didn&#39;t know how it was going to look and we were all praying that didn&#39;t look like shit. Um, so he got lucky, I guess. Um, it&#39;s definitely, it&#39;s not as technically.</p>
                            <p>Bryan White (01:25:54.185)
                            impressive as the Baker transformation, but I think it&#39;s just it&#39;s a it&#39;s just it&#39;s a lot better.</p>
                            <p>Dave (01:26:00.111)
                            I think they&#39;re conveying different things. The American werewolf one, I think you&#39;re supposed to get a sense of like the pain that he&#39;s going through. Like you&#39;re really supposed to empathize with him. Whereas this one, it&#39;s just like here&#39;s Lon Chaney turning into a werewolf, but cooler.</p>
                            <p>Bryan White (01:26:15.989)
                            Yeah, and also there are different kinds of werewolves. Like this is really more Wolfman, where American werewolf, it&#39;s he turns into like a wolf on all fours. And in this one, they&#39;re like upright standing. They&#39;re like half human, half wolf kind of things. They&#39;re both great. I don&#39;t want to say like that, like that, like the other one is qualitatively worse. It&#39;s just I like this one better. It&#39;s just a lot nastier because like also like his like his. Yeah.</p>
                            <p>Dave (01:26:32.258)
                            with big pointy ears.</p>
                            <p>Dave (01:26:42.501)
                            They&#39;re trying to achieve different ends.</p>
                            <p>Bryan White (01:26:45.781)
                            Like this one, like his clothes are getting ripped off and like he&#39;s all of a sudden just like bursting and it&#39;s just like wolf fur all over the place. It&#39;s great. It&#39;s just great. But Karen Karen is is she&#39;s not frozen with fear. She&#39;s got conveniently a jar of Jar of acid, which just happens to be in every doctor&#39;s office. She turns it on jars it throws it at him and it&#39;s enough to</p>
                            <p>Dave (01:27:03.006)
                            Just a random jar of acid.</p>
                            <p>Bryan White (01:27:13.785)
                            the wolf in significant, you know, sufficient pain to get him to get her out of there. We cut to a gas station where Chris is on his way up to meet her. He&#39;s got, and again, I don&#39;t know what the fucking deal with this scene is, but he stops at a gas station. Yeah, he gets, he&#39;s like, hey man, can you like speed this up or something? The guy turns to him and he&#39;s basically, he&#39;s driving a little like Mazda sports car. The guy goes, hey, not all of us can afford a Mazda.</p>
                            <p>Dave (01:27:27.855)
                            This is like the best line of the movie.</p>
                            <p>Dave (01:27:41.834)
                            Was that a real hot brand or something? I didn&#39;t know.</p>
                            <p>Bryan White (01:27:44.017)
                            It must have been. Like, I don&#39;t know. I don&#39;t know. It&#39;s not a Mazda. Sucked. But now we&#39;re basically, we&#39;re rounding the corner. We&#39;re coming into the third act. We cut back to, oh, but also before this happens, there&#39;s a real quick shot back to the occult store where he basically goes in, grabs a box of bullets, just throws a lot of cash at Walter Paisley.</p>
                            <p>Dave (01:27:50.166)
                            Yeah, mine did too.</p>
                            <p>Bryan White (01:28:10.733)
                            who&#39;s like, hey, I got to take these to the jeweler. I don&#39;t know what to charge for me. He&#39;s like, Bill me. It runs out the door. So now silver bullets are in play. But we go back and we&#39;re at the barn where all of the wolves have. But they&#39;re not wolves yet, but they have Karen and they&#39;re taking her to the barn, which is like the ritual area or something like that. This is the big reveal. We&#39;re all werewolves. And there&#39;s really kind of two factions at play here. And Dr. Wagner and the colony.</p>
                            <p>Bryan White (01:28:40.741)
                            exists as a means to sort of like rehabilitate the wolves and sort of like tame them so that they aren&#39;t just like wild animals running wild killing people all over the place.</p>
                            <p>Dave (01:28:52.47)
                            Yeah, this all starts to feel real Bethesda GameZ to me.</p>
                            <p>Bryan White (01:28:56.901)
                            right. Because at the other side of it is Marsha, whose whole thing is like, we&#39;re not meant to be tamed. And I&#39;m carroting actually has a line that&#39;s like you&#39;re trying to tame what can&#39;t be tamed or something like that. It&#39;s it&#39;s it&#39;s the</p>
                            <p>Dave (01:29:12.234)
                            And that line is lifted from something else. I can&#39;t remember what it is, but it&#39;s a quote from something.</p>
                            <p>Bryan White (01:29:18.793)
                            Ah, but yeah, like their whole thing with the quests is they&#39;re trying to get the wolves to sort of like embrace their wildness. And Chris shows up and he goes into the doctor&#39;s office again. But he&#39;s got a rifle with the bullets and this time Eddie is there. He keeps calling him bright boy.</p>
                            <p>Dave (01:29:38.026)
                            Yeah, that&#39;s another one that sounds really weird.</p>
                            <p>Bryan White (01:29:40.829)
                            It also it feels like it&#39;s from something because I there&#39;s like no reason for him to call him that I don&#39;t know why. Yeah, because he does it a couple of times that he does it. But Eddie is now he&#39;s like.</p>
                            <p>Dave (01:29:45.81)
                            And he says it with emphasis every time.</p>
                            <p>Dave (01:29:52.854)
                            But even this dude, he&#39;s just like, oh no, my wife is dead. Oh well. Onward and upward.</p>
                            <p>Bryan White (01:29:56.693)
                            Yeah, right. Yeah. Yeah. But Eddie is all fucked up because back in human form now, his face is all jacked up from the acid. It&#39;s like he&#39;s like visible bone and stuff like that. It&#39;s pretty wild. But he gives Chris the rifle back and he basically invites him to shoot him because like, hey, nothing can kill me. No such thing as silver bullets. Well, jokes on him because he gets shot and it takes him out.</p>
                            <p>Dave (01:30:25.642)
                            Yeah. That&#39;s hubris, Eddiequist.</p>
                            <p>Bryan White (01:30:27.641)
                            Yep. And so he then runs from there to the barn to rescue Karen. And all of the wolves kind of come at Chris but they&#39;re not again, they&#39;re not wolves yet. But there&#39;s</p>
                            <p>Dave (01:30:37.546)
                            And this scene is so, Roger Corman is all over this movie, but particularly this scene, the way it&#39;s lit, the way it all looks like a weird soundstage.</p>
                            <p>Bryan White (01:30:47.469)
                            Yeah, yeah, you kind of expect Vince Price to show up.</p>
                            <p>Dave (01:30:50.75)
                            Yeah, I mean, it&#39;s great. It&#39;s it&#39;s a great moment. It&#39;s just like, there&#39;s a lot of like really intentional throwback shit. And I think this scene is one of them.</p>
                            <p>Bryan White (01:30:59.381)
                            Yep. Yep. I mean, just the inclusion of John Carradine really kind of kind of hammers that home. But again, like they all come at Chris TC starts to transform. Yep, only got one hand is very angry about that. Then again, I&#39;d be pretty upset about that, too. But he</p>
                            <p>Dave (01:31:11.99)
                            It&#39;s only got one hand.</p>
                            <p>Dave (01:31:18.414)
                            Well, Walter Paisley told us it&#39;s gonna grow back. But not for TC, it&#39;s not.</p>
                            <p>Bryan White (01:31:24.085)
                            Nope, because he gets shot with silver bullets.</p>
                            <p>Dave (01:31:29.922)
                            For a dude with a like a bolt action rifle, he is really he&#39;s a good shot.</p>
                            <p>Bryan White (01:31:35.733)
                            Yep, yep. They shoot everybody in the stomach, which is so like, ah, yep, but he gets, he gets Wagner. He gets Karen back, and they shut the, they shut the barn up, where all the wolves are inside. And now they&#39;re all transforming, they&#39;re all transformed. And they sort of douse it with gas and set it on fire. Because again, one of the other things that Walter Paisley told him, it&#39;s either silver bullets or it&#39;s fire that&#39;ll kill him.</p>
                            <p>Dave (01:31:41.608)
                            Center of Mass.</p>
                            <p>Bryan White (01:32:06.589)
                            So they set the place on fire, the wolves are stuck inside and they&#39;re trying to get out. And we think in this moment we find out that Karen thinks that Bill is dead. She doesn&#39;t realize that he&#39;s like among them. Yep. So they they make their escape, there&#39;s a wolf attack.</p>
                            <p>Dave (01:32:17.147)
                            Oh, oh Karen.</p>
                            <p>Dave (01:32:21.774)
                            That&#39;s that&#39;s your Chekhov&#39;s bill is what that is. Your Chekhov&#39;s Tom Atkins.</p>
                            <p>Bryan White (01:32:27.205)
                            Yeah, they get a they&#39;re starting to they started to make their escape and one of the thing they get stopped they have to deal with slim pickings who&#39;s still in that thing. Yep, slim pickings just coming apart at the seams. And he gets shot with silver bullets. They move to his car to get away because their car has been all shot up the Mazda explodes</p>
                            <p>Dave (01:32:35.306)
                            Yeah, you get stopped by Slim Pickens. Just bursting out of that suit.</p>
                            <p>Dave (01:32:50.834)
                            And then it explodes in a ball of flame.</p>
                            <p>Bryan White (01:32:53.769)
                            Yep, because you gotta it&#39;s awesome. That&#39;s why you do it. And they&#39;re about to get away when they&#39;re attacked again by a wolf, which bites Karen. But she manages to crack a shot off, kills him and the wolf falls dead in the backseat. And they find out it&#39;s Bill&#39;s body. So now they&#39;re away and they&#39;re back at the TV station. Karen decides that they got to warn everybody. They got to let them know.</p>
                            <p>Dave (01:33:11.502)
                            And you know it. That&#39;s right.</p>
                            <p>Dave (01:33:20.598)
                            Must not sleep, must warn others. There we go.</p>
                            <p>Bryan White (01:33:25.498)
                            Yep. So she goes on TV. She&#39;s part of the regular, you know, anchor crew. And she gives a monologue and goes and tells everybody that there&#39;s a werewolf colony and they live as like a secret society among us. And then because she knows that nobody&#39;s going to believe her, she transforms. And this is like the big, the big moment.</p>
                            <p>Dave (01:33:42.974)
                            And man, this fucking scene starts off so cool.</p>
                            <p>Bryan White (01:33:47.517)
                            It&#39;s so awesome. It really is. It&#39;s because like</p>
                            <p>Dave (01:33:51.114)
                            and then they just kick you right in the dick.</p>
                            <p>Bryan White (01:33:55.553)
                            They do. They do because like she&#39;s very pain. She&#39;s doing it. She&#39;s got that awesome eye reflection effect again and she does that.</p>
                            <p>Dave (01:33:57.702)
                            Ugh.</p>
                            <p>Dave (01:34:01.678)
                            Yep. And like all the camp, like the lights are hot and like all on her face. It&#39;s so gnarly.</p>
                            <p>Bryan White (01:34:07.162)
                            she does that big, she does a great scream. And then she begins to, she begins to transform into a fucking puppy.</p>
                            <p>Dave (01:34:15.638)
                            Yep. Lady on the news turned into a werewolf.</p>
                            <p>Bryan White (01:34:18.037)
                            Yep. Like she&#39;s like where the others are all like gnarly Muppet werewolves. She looks like she looks like a toy dog that a rich lady carries around in a purse.</p>
                            <p>Dave (01:34:27.906)
                            So my pro- actually I want to go back because I didn&#39;t- I never addressed the thing I said I was going to. The reason why they all keep saying, aren&#39;t you famous? Because what he says at the end in the barn is, you know we can&#39;t get rid of her, she&#39;s famous. People know who she is. That&#39;s why I think they keep doing that. Because- so everyone knows that she is a famous person.</p>
                            <p>Bryan White (01:34:44.473)
                            Uhhhh</p>
                            <p>Bryan White (01:34:48.402)
                            Okay.</p>
                            <p>Bryan White (01:34:51.265)
                            Gotcha. Alright. It&#39;s still, it&#39;s a little loose, but it doesn&#39;t make any sense why she&#39;s up there at all.</p>
                            <p>Dave (01:34:55.446)
                            But my, my-</p>
                            <p>Dave (01:34:58.566)
                            No, no, my problem with this ending is that it&#39;s not only does it look like shit, and not only do they pull the rug out from under you during what is such a cool scene, it&#39;s that she doesn&#39;t look anything like the rest of them. So if she&#39;s gonna be a werewolf, why doesn&#39;t she just look like, they all look the fucking same. Why doesn&#39;t she look like one of them too? Instead she looks like a Shih Tzu.</p>
                            <p>Bryan White (01:35:10.902)
                            Yeah.</p>
                            <p>Bryan White (01:35:16.045)
                            The thing I-</p>
                            <p>Bryan White (01:35:20.937)
                            Now, yeah, the line officially, if you look it up, and I think it&#39;s like, if you look it up in like IMDB trivia, like she specifically was like, I don&#39;t want to be an Asti werewolf. I want to be like feminine or something like that. But it&#39;s like that doesn&#39;t. Doesn&#39;t I don&#39;t know. I don&#39;t know if I trust that because.</p>
                            <p>Dave (01:35:34.011)
                            Oh, fuck off, D. Wallace, you can&#39;t do that.</p>
                            <p>Dave (01:35:40.634)
                            that&#39;s some dumb shit that she wouldn&#39;t say, that no one would say.</p>
                            <p>Bryan White (01:35:42.281)
                            Like, couldn&#39;t they have just reused one of the other suits? Or like, at least the head, because you don&#39;t even really see her full body. It&#39;s just all tight closeup on what looks like a really small model.</p>
                            <p>Dave (01:35:58.942)
                            Also, that supposed explanation has a little bit of misogyny whiff about it because it&#39;s like, this woman is a professional. I cannot imagine she would walk in and be like, I want my werewolf to be pretty.</p>
                            <p>Bryan White (01:36:04.765)
                            Ah.</p>
                            <p>Bryan White (01:36:12.029)
                            Maybe, but I mean, this comes from her specifically, so I don&#39;t fucking know. I don&#39;t know. I think it&#39;s just them explaining away a crappy werewolf at the end of a movie with... Yeah, thanks lady. I hope you&#39;re happy with E.T.</p>
                            <p>Dave (01:36:22.378)
                            Yeah, well, you ruined the end of the movie, D. Wallace. I hope you&#39;re happy with yourself. That&#39;s why you didn&#39;t get famous. Or, famous-er.</p>
                            <p>Bryan White (01:36:32.301)
                            But yeah, so she&#39;s transformed into this crappy, crappy lady werewolf. I mean, it&#39;s a hell of a scene otherwise. It just, good God. It&#39;s such, it lands with such a squish. Like it&#39;s just, it&#39;s not, it could have, yeah. But before she could run wild, Chris, who got a rifle with him at the workplace.</p>
                            <p>Dave (01:36:45.075)
                            I&#39;m a thud.</p>
                            <p>Dave (01:36:52.062)
                            Nobody says a thing about it. Everyone else is freaking out. Chris is just cool as can be. Loads the gun and shoots her in the head.</p>
                            <p>Bryan White (01:36:53.685)
                            Nobody says anything.</p>
                            <p>Bryan White (01:36:59.917)
                            she shoots her. And then we cut to some audience reactions for a little bit of comedic effect and they cut to a couple who we see where the guy says hey what is this that is future director and Stephen King addict Mick Garris. Yep there&#39;s a couple of kids who are the moms like hey what are you guys watching they&#39;re like hey the lady on the news is turning into a werewolf.</p>
                            <p>Dave (01:37:23.937)
                            Yep.</p>
                            <p>Bryan White (01:37:26.53)
                            right? And, and then we we, we cut away from that to like a roadhouse. And we find out that Marsha has escaped. And then the credits. Yeah. And then the credits roll over a hamburger being cooked.</p>
                            <p>Dave (01:37:35.242)
                            BOOOO!</p>
                            <p>Dave (01:37:40.662)
                            Which is like the, it&#39;s the equivalent of eating the hamburger in the morgue. It&#39;s that like someone gets their head cut off and then splat on a plate of ketchup.</p>
                            <p>Bryan White (01:37:46.808)
                            Yeah.</p>
                            <p>Bryan White (01:37:51.189)
                            Yep. Is it weird that like when the guy goes to flip the burger, it practically comes apart and that I&#39;m really weirded out by that? Like when that happened, I was like, oh my God, that burger sucks. Yeah. Right. Because he turns to her, he&#39;s like, hey, how do you like your burger? She&#39;s like rare or something like this or extra rare. It&#39;s like, oh my God, just shut up. Just eat the fucking thing raw. But the end, the howling.</p>
                            <p>Dave (01:38:00.59)
                            I think it&#39;s just gross to watch it happen. I think that&#39;s the point, is that it&#39;s just gross. We&#39;re just here to be disgusting.</p>
                            <p>Dave (01:38:11.298)
                            Ugh, get the fuck out!</p>
                            <p>Bryan White (01:38:19.83)
                            and otherwise great movie.</p>
                            <p>Dave (01:38:21.134)
                            That is a killer. That movie is so good.</p>
                            <p>Bryan White (01:38:23.401)
                            It&#39;s so good and it just you can just tell that they hit the wall with money at the end and it&#39;s really too bad. But Jesus Christ, I mean, yeah, I mean, it was enough to move Steven Spielberg because like.</p>
                            <p>Dave (01:38:31.694)
                            I mean, he got gremlins out of it.</p>
                            <p>Dave (01:38:37.362)
                            It&#39;s weird that that&#39;s like the last, like that&#39;s the most it did. And then he sort of peters out. He keeps making movies, but, you know, and they&#39;re good movies. But they&#39;re nowhere near as high profile as it&#39;s like Toby Hooper gets poltergeist and then makes dog shit after that.</p>
                            <p>Bryan White (01:38:45.505)
                            Well, yeah, yeah. Well, I mean, I do.</p>
                            <p>Bryan White (01:38:56.865)
                            Well, I mean, well yeah, I mean I know what you&#39;re saying like...</p>
                            <p>Dave (01:38:58.474)
                            I guess Steven Spielberg doesn&#39;t want to carry the team forever.</p>
                            <p>Bryan White (01:39:01.717)
                            nothing yeah like nothing nothing really does I mean that is a that is a fair point nothing really compares to poltergeist and gremlins like these are two humongous fucking movies in the 80s but like Joe Dante makes inner space which is you know</p>
                            <p>Dave (01:39:16.81)
                            Yeah, I mean, he made Matinee, he made Innerspace, he made the burbs. Like, there&#39;s good fucking movies with like. No.</p>
                            <p>Bryan White (01:39:21.145)
                            They&#39;re not the gremlins though. Um, and yeah, and then Toby Hooper.</p>
                            <p>Dave (01:39:26.062)
                            Because they don&#39;t have Papa Spielberg&#39;s name, like in TAC-TONE.</p>
                            <p>Bryan White (01:39:28.481)
                            This is true. I think Hooper&#39;s problem was he shacked up with fucking canon.</p>
                            <p>Dave (01:39:33.626)
                            I hope he had a lot of problems, and I think his personality was probably the biggest one.</p>
                            <p>Bryan White (01:39:37.417)
                            Yeah, but yeah, Joe Dante is the Howling, a movie that I like tremendously. Yep.</p>
                            <p>Dave (01:39:42.158)
                            I love the movie. You know, and there is a world in which this movie is absolutely terrible. Like, this is so on the line of falling apart all the time that it is a wonder that it actually works.</p>
                            <p>Bryan White (01:39:54.217)
                            Right. Well, this is like this goes back to one of the ones that we talked about with the Sentinel, where it was a movie. I think that where it got picked up as soon it was optioned as soon as it was published. And it went through a lot like a lot of like development problems because Dante and sales were not the original director and writer. Like this went through a bunch of. Yeah. So this went through a bunch of writers. It had another director attached to it.</p>
                            <p>Dave (01:40:16.382)
                            Because this is Avco, right? Yep. So they&#39;re banking on like another, having another carpenter success. Because that&#39;s where all their money came from at that point.</p>
                            <p>Bryan White (01:40:26.345)
                            Yeah, yeah. Yeah, I mean, ultimately it did. I mean, it was a very successful movie. So, you know, good for them. Well, yeah. So what are we doing next?</p>
                            <p>Dave (01:40:41.682)
                            Well, I tell you, we&#39;re going to get into some themes now. We&#39;re going to get into a theme month. Let me consult my list here. Because it is now going to be May, we&#39;re getting into the schools out time. Yeah, and we&#39;re going to go for a real deep cut here. I just looked at my list and then totally threw it aside. Final exam. Now have you seen final exam?</p>
                            <p>Bryan White (01:40:55.035)
                            Oh yeah.</p>
                            <p>Bryan White (01:41:02.913)
                            Final exam. This okay. I&#39;ve I&#39;ve not and I had never I you know, I&#39;ve never even heard of it until you mentioned it but it also it just landed on shutter. So the timing is real good.</p>
                            <p>Dave (01:41:14.558)
                            It is a, it&#39;s a bit of a bargain bin slasher. I think it has a lot of charm. It is not good. The acting is laughable, but it has its moments. It is very odd. And</p>
                            <p>Bryan White (01:41:24.556)
                            Hahaha</p>
                            <p>Bryan White (01:41:28.361)
                            Some of, I&#39;ll tell you what, some of my favorite episodes that we&#39;ve done so far are on some of the lower end movies. Like they tend to be the most just outwardly entertaining flicks.</p>
                            <p>Dave (01:41:37.954)
                            So, well, this is gonna be one because it is a great movie. I have seen it several times and it is a fun watch.</p>
                            <p>Bryan White (01:41:44.221)
                            Alright, awesome. So join us back here two weeks when we do final exam.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/final-exam">Next Episode</Link></li>
                        <li><Link to="/episodes/the-sentinel">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)